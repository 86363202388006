import React from "react";

export const Section1Img1 = (props) => {
    return <svg {...props} viewBox="0 0 299 330" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M196.575 266.772H10.425C5.09833 266.772 0.763336 262.437 0.763336 257.11V10.2416C0.763336 4.91496 5.09833 0.579956 10.425 0.579956H196.603C201.93 0.579956 206.265 4.91496 206.265 10.2416V257.138C206.265 262.465 201.93 266.772 196.575 266.772Z" fill="#B3B3B3"/>
        <path d="M18.7833 25.8533C22.5232 25.8533 25.555 22.8215 25.555 19.0816C25.555 15.3417 22.5232 12.3099 18.7833 12.3099C15.0434 12.3099 12.0117 15.3417 12.0117 19.0816C12.0117 22.8215 15.0434 25.8533 18.7833 25.8533Z" fill="#FF1E1C"/>
        <path d="M196.462 34.6649H10.51V202.597H196.462V34.6649Z" fill="#00932E"/>
        <path d="M179.377 90.425H134.752C132.683 90.425 131.012 88.7533 131.012 86.685V53.45C131.012 51.3816 132.683 49.71 134.752 49.71H179.377C181.445 49.71 183.117 51.3816 183.117 53.45V86.685C183.117 88.7533 181.445 90.425 179.377 90.425Z" fill="#B3B3B3"/>
        <path d="M180.538 53.1949H133.59V86.9116H180.538V53.1949Z" fill="#00932E"/>
        <path d="M38.73 202.285L35.3867 202.88L14.4483 86.5716L30.9383 59.9382C31.5333 60.9299 32.355 61.7799 33.4317 62.4032L18.0466 87.2799L38.73 202.285Z" fill="black"/>
        <path d="M61.7367 50.6732L48.4483 79.0632L36.2366 68.2116L38.6166 63.1399L43.2067 53.3082L45.5866 48.2366L61.7367 50.6732Z" fill="#B3B3B3"/>
        <path d="M43.2067 53.3082L38.6167 63.1399C37.115 63.5082 35.5 63.3949 33.9983 62.6866C33.8 62.6016 33.63 62.4882 33.4317 62.4032C32.3833 61.7799 31.5333 60.9299 30.9383 59.9382C29.805 58.0116 29.5783 55.5749 30.5983 53.3932C32.2417 49.9082 36.4067 48.4066 39.8917 50.0216C41.3933 50.7299 42.5267 51.9199 43.2067 53.3082Z" fill="white"/>
        <path d="M74.4017 12.3099H39.6083V17.1549H74.4017V12.3099Z" fill="#FF1E1C"/>
        <path d="M89.8434 21.0083H39.6083V25.8533H89.8434V21.0083Z" fill="#FF1E1C"/>
        <path d="M196.49 229.74H14.5333V234.585H196.49V229.74Z" fill="white"/>
        <path d="M196.49 239.827H14.5333V244.672H196.49V239.827Z" fill="white"/>
        <path d="M18.5283 211.635C16.8567 209.538 11.3033 209 11.6717 214.61C11.9267 218.293 17.48 220.9 18.5283 221.608C19.605 220.9 25.1583 218.293 25.385 214.61C25.7533 209 20.2 209.567 18.5283 211.635Z" fill="#FF5876"/>
        <path d="M46.38 221.608L45.6717 218.293C46.0684 217.557 46.295 216.735 46.295 215.885C46.295 212.712 43.32 210.162 39.6367 210.162C35.9533 210.162 32.9783 212.712 32.9783 215.885C32.9783 219.058 35.9533 221.608 39.6367 221.608C40.9967 221.608 42.2434 221.268 43.2917 220.673L46.38 221.608Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M66.4684 213.703L54.9366 210.275L59.1017 215.177L66.4684 213.703Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M66.4399 213.732L57.0333 221.297L59.0733 215.177L66.4399 213.732Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M18.5283 211.635C16.8567 209.538 11.3033 209 11.6717 214.61C11.9267 218.293 17.48 220.9 18.5283 221.608C19.605 220.9 25.1583 218.293 25.385 214.61C25.7533 209 20.2 209.567 18.5283 211.635Z" fill="#FF5876"/>
        <path d="M46.38 221.608L45.6717 218.293C46.0684 217.557 46.295 216.735 46.295 215.885C46.295 212.712 43.32 210.162 39.6367 210.162C35.9533 210.162 32.9783 212.712 32.9783 215.885C32.9783 219.058 35.9533 221.608 39.6367 221.608C40.9967 221.608 42.2434 221.268 43.2917 220.673L46.38 221.608Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M66.4684 213.703L54.9366 210.275L59.1017 215.177L66.4684 213.703Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M66.4399 213.732L57.0333 221.297L59.0733 215.177L66.4399 213.732Z" stroke="#06085D" strokeMiterlimit="10"/>
        <path d="M119.678 30.6983C119.678 28.8849 118.29 27.3833 116.477 27.0999V16.5599H90.1833V27.0433C88.1716 27.1283 86.585 28.7149 86.585 30.6699C86.585 32.6816 88.285 34.2966 90.3816 34.2966C91.4583 40.4733 96.8417 45.2049 103.33 45.2049C109.818 45.2049 115.23 40.4733 116.278 34.2966C118.177 34.1266 119.678 32.5683 119.678 30.6983Z" fill="white"/>
        <path d="M134.412 65.69L134.922 66.0016L135.375 66.285L136.253 66.8233C136.82 67.1916 137.387 67.56 137.953 67.9C139.058 68.6366 140.163 69.345 141.24 70.11C143.422 71.5833 145.518 73.1133 147.643 74.6716C151.837 77.7883 155.917 81.0183 159.855 84.39C160.847 85.24 161.838 86.09 162.802 86.94C163.765 87.8183 164.757 88.6683 165.72 89.5466L168.525 92.1533C169.318 92.89 170.14 93.6266 170.962 94.3633C174.305 97.2533 178.017 99.9733 181.87 102.58C189.605 107.737 197.992 112.327 206.633 116.492C210.94 118.588 215.332 120.543 219.78 122.442C221.99 123.377 224.228 124.283 226.467 125.162C227.572 125.615 228.705 126.04 229.81 126.465L231.482 127.088L233.068 127.655L233.295 127.74C238.253 129.525 240.832 135.022 239.018 139.98C237.233 144.882 231.878 147.432 226.977 145.76L225.05 145.108L223.208 144.457C221.99 144.032 220.772 143.578 219.582 143.125C217.173 142.218 214.793 141.312 212.413 140.348C207.653 138.45 202.95 136.438 198.275 134.285C188.925 129.978 179.745 125.218 170.82 119.523C166.372 116.662 161.98 113.573 157.73 110.06C156.682 109.182 155.633 108.275 154.585 107.34L151.893 104.932C151.015 104.138 150.108 103.373 149.23 102.608C148.352 101.843 147.417 101.078 146.51 100.342C142.855 97.3383 139.115 94.4483 135.262 91.7C133.335 90.34 131.408 88.98 129.453 87.705C128.49 87.0533 127.498 86.43 126.535 85.8066C126.053 85.495 125.572 85.2116 125.09 84.9L124.382 84.475L123.758 84.1066L123.56 83.9933C118.488 81.0183 116.817 74.5016 119.792 69.4583C122.767 64.3866 129.283 62.715 134.327 65.69H134.412Z" fill="white"/>
        <path d="M208.022 140.15L211.875 126.777C212.385 124.992 214.283 123.943 216.068 124.453L243.552 132.358C245.337 132.868 246.385 134.767 245.875 136.552L242.022 149.925C241.512 151.71 239.613 152.758 237.828 152.248L210.345 144.343C208.56 143.833 207.512 141.935 208.022 140.15Z" fill="white"/>
        <path d="M209.495 139.753L212.923 127.853C213.433 126.068 215.332 125.02 217.117 125.53L242.107 132.727C243.892 133.237 244.94 135.135 244.43 136.92L241.002 148.82C240.492 150.605 238.593 151.653 236.808 151.143L211.818 143.947C210.033 143.465 208.985 141.567 209.495 139.753Z" fill="#00932E"/>
        <path d="M218.052 137.572C216.437 137.572 215.105 136.24 215.105 134.625V124.878C215.105 123.263 216.437 121.932 218.052 121.932C219.667 121.932 220.998 123.263 220.998 124.878V134.625C220.97 136.268 219.667 137.572 218.052 137.572Z" fill="white"/>
        <path d="M106.503 42.5983V51.2683C105.823 51.2116 105.115 51.2116 104.435 51.2116C102.99 51.2116 101.545 51.3249 100.128 51.5233V42.6266H106.503V42.5983Z" fill="white"/>
        <path d="M90.155 25.8533C90.155 25.8533 84.5734 3.46998 105.285 5.67998C111.348 6.33165 118.035 5.02832 118.035 5.02832C118.035 5.02832 119.197 9.95832 116.533 12.3383C116.533 12.3383 118.573 14.8033 116.533 23.445C116.533 23.445 113.558 15.9933 103.16 17.155C92.7334 18.345 90.2117 24.8617 90.155 25.8533Z" fill="black"/>
        <path d="M102.197 28.2616C102.197 28.2616 103.217 31.2083 101.148 34.6366C101.148 34.6366 101.148 36.5066 103.33 36.5633" stroke="black" strokeMiterlimit="10"/>
        <path d="M95.7934 34.6366C99.3142 34.6366 102.168 31.7824 102.168 28.2616C102.168 24.7408 99.3142 21.8866 95.7934 21.8866C92.2725 21.8866 89.4184 24.7408 89.4184 28.2616C89.4184 31.7824 92.2725 34.6366 95.7934 34.6366Z" fill="white" stroke="black" strokeMiterlimit="10"/>
        <path d="M95.7934 29.9899C96.4193 29.9899 96.9267 29.2161 96.9267 28.2615C96.9267 27.307 96.4193 26.5332 95.7934 26.5332C95.1674 26.5332 94.66 27.307 94.66 28.2615C94.66 29.2161 95.1674 29.9899 95.7934 29.9899Z" fill="black"/>
        <path d="M110.81 34.6366C114.331 34.6366 117.185 31.7824 117.185 28.2616C117.185 24.7408 114.331 21.8866 110.81 21.8866C107.289 21.8866 104.435 24.7408 104.435 28.2616C104.435 31.7824 107.289 34.6366 110.81 34.6366Z" fill="white"/>
        <path d="M110.81 29.9899C111.436 29.9899 111.943 29.2161 111.943 28.2615C111.943 27.307 111.436 26.5332 110.81 26.5332C110.184 26.5332 109.677 27.307 109.677 28.2615C109.677 29.2161 110.184 29.9899 110.81 29.9899Z" fill="black"/>
        <path d="M110.81 34.6366C114.331 34.6366 117.185 31.7824 117.185 28.2616C117.185 24.7408 114.331 21.8866 110.81 21.8866C107.289 21.8866 104.435 24.7408 104.435 28.2616C104.435 31.7824 107.289 34.6366 110.81 34.6366Z" stroke="black" strokeMiterlimit="10"/>
        <path d="M148.352 148.253L58.79 157.972L58.8466 157.49C59.3566 155.592 63.8616 148.622 102.197 145.987C102.197 145.987 140.39 144.06 148.352 148.253Z" fill="#AD1E1C"/>
        <path d="M100.128 48.2367C100.128 48.2367 104.237 47.6417 106.503 44.8367C106.503 44.8367 104.945 45.29 103.528 45.2333C102.112 45.1767 100.128 44.8367 100.128 44.8367V48.2367Z" fill="#B3B3B3"/>
        <path d="M54.4833 202.568L64.1733 148.253L105.852 146.157L96.8983 202.568H54.4833Z" fill="black"/>
        <path d="M93.555 77.0233L93.47 77.0799L93.2717 77.1933L92.5917 77.59L91.8267 78.0716C91.3167 78.3833 90.7784 78.7233 90.2684 79.0633C89.22 79.7433 88.1717 80.4233 87.1233 81.1316C85.0267 82.5483 82.9583 84.0216 80.89 85.5233C76.7817 88.555 72.7867 91.7283 68.905 95.0149L66.015 97.5366L63.1817 100.115C61.34 101.843 59.5267 103.572 57.77 105.357C56.8917 106.235 56.0417 107.17 55.1633 108.077C54.3134 109.012 53.4633 109.918 52.6417 110.853C50.97 112.723 49.355 114.65 47.7967 116.605C46.21 118.56 44.68 120.572 43.1783 122.612C41.6767 124.652 40.2033 126.748 38.7867 128.902C35.8967 133.152 33.12 137.6 30.3433 142.105C27.5667 146.61 24.7617 151.2 21.9 155.818C19.0383 160.437 16.0917 165.027 13.06 169.588C12.3233 170.693 11.3883 171.6 10.3116 172.308V137.345C11.3883 135.532 12.4367 133.747 13.5133 131.933C16.29 127.287 19.1233 122.583 22.155 117.908C23.6567 115.585 25.2433 113.233 26.8583 110.938C28.4733 108.643 30.145 106.348 31.9017 104.11C33.63 101.872 35.4433 99.6616 37.285 97.4799C38.1917 96.4033 39.155 95.3266 40.1183 94.2783C41.0817 93.2299 42.045 92.1816 43.0367 91.1616C44.9917 89.0933 47.0317 87.0816 49.0717 85.1266L52.16 82.2366L55.305 79.4033C59.4984 75.6916 63.8617 72.1216 68.3384 68.7216C70.5767 67.0216 72.8434 65.3499 75.1667 63.7349C76.3284 62.9133 77.49 62.1199 78.68 61.3266C79.275 60.9299 79.87 60.5333 80.4934 60.1366L81.4284 59.5416L81.91 59.2299L82.4767 58.89C87.4917 55.83 94.0367 57.3883 97.0967 62.4033C100.128 67.4183 98.5417 73.9633 93.555 77.0233Z" fill="white"/>
        <path d="M148.352 107.652L142.912 102.75L148.352 148.225C140.418 144.032 102.197 145.958 102.197 145.958C64.145 148.565 59.4133 155.478 58.8467 157.433C59.1017 156.272 63.9183 133.577 64.825 125.785C65.4483 120.373 65.675 103.997 65.675 103.997L59.98 109.267L52.415 75.125C62.7283 69.4867 73.0417 63.8483 83.355 58.21C85.565 56.8783 87.4916 55.8583 88.965 55.15C90.8917 54.215 92.2233 53.5634 94.15 52.9117C95.7933 52.345 97.4933 51.92 99.2217 51.6367C99.5333 51.58 99.8167 51.5517 100.128 51.495C101.545 51.2967 102.99 51.1833 104.435 51.1833C105.115 51.1833 105.823 51.2117 106.503 51.24C108.458 51.3817 110.385 51.6933 112.283 52.175C114.833 52.8267 117.327 53.79 119.678 55.0367C120.273 55.3484 120.868 55.6884 121.435 56.0284L154.84 74.8133L148.352 107.652Z" fill="#FF1E1C"/>
        <path d="M143.053 102.892L148.352 107.652L149.853 100.087L134.553 90.4249L148.352 148.253L143.053 102.892Z" fill="#AD1E1C"/>
        <path d="M103.528 149.783C103.642 149.783 103.443 129.1 123.815 124.793C144.442 120.43 150.845 142.473 150.732 142.53C164.388 190.725 171.84 241.583 172.237 293.773C156.54 294.227 140.872 294.482 125.203 294.538C124.75 244.332 117.213 195.655 103.528 149.783Z" fill="black"/>
        <path d="M174.645 297.117C174.645 297.117 171.868 276.122 154.047 275.867C136.225 275.612 127.498 293.377 127.13 299.922C126.79 306.467 174.645 297.117 174.645 297.117Z" fill="#00932E"/>
        <path d="M127.47 304.002C127.612 304.965 127.867 305.872 128.235 306.75C129.085 298.25 139.002 290.628 151.582 289.58C162.462 288.673 171.897 293.15 174.73 300.063C174.815 299.072 174.815 298.08 174.645 297.088C173.313 288.475 162.433 282.695 149.967 284.027C136.31 285.528 126.138 294.68 127.47 304.002Z" fill="white"/>
        <path d="M131.153 311.142C133.137 313.153 134.185 315.618 134.043 318.197C134.015 318.735 134.043 319.273 134.128 319.812C135.092 326.498 143.988 330.692 153.707 328.85C161.753 327.292 167.732 321.88 167.703 316.157C167.703 316.128 167.703 316.072 167.703 316.043C167.647 313.408 168.695 310.773 170.792 308.422C173.03 305.9 174.418 303.067 174.73 300.12C171.897 293.207 162.462 288.73 151.582 289.637C139.03 290.685 129.113 298.307 128.235 306.807C128.887 308.365 129.878 309.838 131.153 311.142Z" fill="black"/>
        <path d="M238.045 111.941L241.288 96.9115C239.507 93.5766 238.502 89.8764 238.502 85.9934C238.502 71.649 252.024 60 268.698 60C285.372 60 298.894 71.649 298.894 85.9934C298.894 100.338 285.372 111.987 268.698 111.987C262.576 111.987 256.866 110.388 252.115 107.693L238.045 111.941Z" fill="white"/>
    </svg>

}