const SexType = {
    Male: 'male',
    Female: 'female'
}
export const questions = [
    {
        id: '1',
        question: 'Как ты отреагируешь \nна предложение стать \nнаставником?',
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Предложение хорошее. Я буду рад помочь своему коллективу и будущему сотруднику, как раз у меня есть на это время',
                    [SexType.Female]: 'Предложение хорошее. Я буду рада помочь своему коллективу и будущему сотруднику, как раз у меня есть на это время'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Отличная возможность прокачать свои навыки — однозначно возьмусь! Для меня важно превратить стажера в полноценного сотрудника компании, круто встретить новые таланты',
                    [SexType.Female]: 'Отличная возможность прокачать свои навыки — однозначно возьмусь! Для меня важно превратить стажера в полноценного сотрудника компании, круто встретить новые таланты'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Готов поработать, раз есть такая необходимость',
                    [SexType.Female]: 'Готова поработать, если есть такая необходимость'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '2',
        question: 'Первый шаг — «я расскажу, а ты послушай». О чем же тут речь? ',
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Везде существуют правила — важно предельно четко разъяснить стажеру, кто он такой и где его место',
                    [SexType.Female]: 'Везде существуют правила — важно предельно четко разъяснить стажеру, кто он такой и где его место'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Тут все предельно ясно: я расскажу стажеру новую информацию, которая будет необходима для успешного выполнения рабочих обязанностей',
                    [SexType.Female]: 'Тут все предельно ясно: я расскажу стажеру новую информацию, которая будет необходима для успешного выполнения рабочих обязанностей'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Да все понятно — нам говорили больше болтать со стажером обо всем. Во время общения все проясняется',
                    [SexType.Female]: 'Да все понятно — нам говорили больше болтать со стажером обо всем. Во время общения все проясняется'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '3',
        question: 'Второй шаг — «я покажу, а ты посмотри». Кажется, что всё понятно, но так ли это?',
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Да, я объясняю все обязанности стажеру. Мое личное влияние — второстепенно, главное — это понимание своей должности',
                    [SexType.Female]: 'Да, я объясняю все обязанности стажеру. Мое личное влияние — второстепенно, главное — это понимание своей должности'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Я являюсь примером для стажера, поначалу он ориентируется на меня. Моя обязанность — показать на личном примере вовлеченность в работу',
                    [SexType.Female]: 'Я являюсь примером для стажера, поначалу он ориентируется на меня. Моя обязанность — показать на личном примере вовлеченность в работу'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Да, конечно понятно. Я показываю офис, где находится кофеварка и как включать компьютер',
                    [SexType.Female]: 'Да, конечно понятно. Я показываю офис, где находится кофеварка и как включать компьютер'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '4',
        question: '«Дай волю!»\nСтоп, а для чего, знаешь?',
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Стажер набрался знаний, и ему пора отправляться в «свободное плавание». Прослежу, как стажер работает самостоятельно, и дам обратную связь',
                    [SexType.Female]: 'Стажер набрался знаний, и ему пора отправляться в «свободное плавание». Прослежу, как стажер работает самостоятельно, и дам обратную связь'
                },
                isCorrect: true,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'На данном этапе можно отпускать стажера, дальше во мне не будет острой необходимости, если что – я на связи',
                    [SexType.Female]: 'На данном этапе можно отпускать стажера, дальше во мне не будет острой необходимости, если что – я на связи'
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Он включает кофеварку сам. \nЯ научил его, я молодец! \n«Биг брейн» в деле',
                    [SexType.Female]: 'Он включает кофеварку сам. \nЯ научил его, я молодец! \n«Биг брейн» в деле'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '5',
        question: 'Пятый шаг – «самостоятельность»\n' +
            'Что, уже все?',
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'По большей части, да. Ко мне можно будет подходить по необходимым вопросам, но всему, что я сам знаю, я уже обучил',
                    [SexType.Female]: 'По большей части, да. Ко мне можно будет подходить по необходимым вопросам, но всему, что я сама знаю, я уже обучила'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Нет, еще не все. Это один их самых важных этапов: мы перешли с «тренировки» на полноценную работу, еще много впереди! Здесь важно проверить его боем! ',
                    [SexType.Female]: 'Нет, еще не все. Это один их самых важных этапов: мы перешли с «тренировки» на полноценную работу, еще много впереди! Здесь важно проверить его боем! '
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Думаю, да. Название же говорит само за себя. Я проверил все, что было нужно, теперь можем пить кофе вместе',
                    [SexType.Female]: 'Думаю, да. Название же говорит само за себя. Я проверила все, что было нужно, теперь можем пить кофе вместе'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '6',
        question: 'В процессе обучения',
        text: {
            [SexType.Male]:'Вам с остальными будущими наставниками рассказывали про теории поколений. Ты внимательно все слушал, но, когда узнал, что твой стажер 2001 года рождения, задумался. К какому поколению он относится?',
            [SexType.Female]: 'Вам с остальными будущими наставниками рассказывали про теории поколений. Ты внимательно все слушала, но, когда узнала, что твой стажер 2001 года рождения, задумалась. К какому поколению он относится?'
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Ой-ой, в новостях крутили, что Трамп – «беби-бумер». Как это поможет мне вспомнить поколение стажера – не знаю. Но выбираю этот вариант:)',
                    [SexType.Female]: 'Ой-ой, в новостях крутили, что Трамп – «беби-бумер». Как это поможет мне вспомнить поколение стажера – не знаю. Но выбираю этот вариант:)'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: '«Поколение Y или индиго» – очень просто! 100% не отпускает из рук смартфон (как и я!)) ',
                    [SexType.Female]: '«Поколение Y или индиго» – очень просто! 100% не отпускает из рук смартфон (как и я!)) '
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Скорее, относится к миллениалам (Y) – помню, что для них важен гибкий график и хорошая зарплата.\n' +
                    'Проблем с этим у нас не будет:)',
                    [SexType.Female]: 'Скорее, относится к миллениалам (Y) – помню, что для них важен гибкий график и хорошая зарплата.\n' +
                    'Проблем с этим у нас не будет:)'
                },
                isCorrect:  true,
            },

        ],
    },
    {
        id: '7',
        question: 'Что ж, остался один день до выхода стажера',
        text: {
            [SexType.Male]:'Ты настроен решительно и уже точно знаешь, что будешь делать в первую очередь:',
            [SexType.Female]: 'Ты настроена решительно и уже точно знаешь, что будешь делать в первую очередь:'
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Нервничать вредно, я так считаю. Поэтому я расслаблюсь, посмотрю новый сериальчик. Завтра сложный рабочий день, надо быть отдохнувшим',
                    [SexType.Female]: 'Нервничать вредно, я так считаю. Поэтому я расслаблюсь, посмотрю новый сериальчик. Завтра сложный рабочий день, надо быть отдохнувшей'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Я позвоню стажеру, узнаю, как идут дела и напомню про завтрашнюю встречу. Отвечу на все вопросы, которые у него появятся',
                    [SexType.Female]: 'Я позвоню стажеру, узнаю, как идут дела и напомню про завтрашнюю встречу. Отвечу на все вопросы, которые у него появятся'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Пожалуй, лучше я еще раз обсужу с коллегами все обязанности стажера, уточню все нюансы',
                    [SexType.Female]: 'Пожалуй, лучше я еще раз обсужу с коллегами все обязанности стажера, уточню все нюансы'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '8',
        question: 'Ура!',
        text: {
            [SexType.Male]:'Первый день стажера. Ты встречаешь молодого, амбициозного, но пока что немного потерянного человека. Вы быстро находите общий язык. Пора приступать к делу: что обязательно надо успеть за первый день? ',
            [SexType.Female]: 'Первый день стажера. Ты встречаешь молодого, амбициозного, но пока что немного потерянного человека. Вы быстро находите общий язык. Пора приступать к делу: что обязательно надо успеть за первый день? '
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Показать стажеру рабочее место, познакомить его со всей командой, объяснить, как пользоваться всеми программами, рабочей почтой и т.д. ',
                    [SexType.Female]: 'Показать стажеру рабочее место, познакомить его со всей командой, объяснить, как пользоваться всеми программами, рабочей почтой и т.д. '
                },
                isCorrect: true,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Взять его с собой на встречу – пусть посмотрит, как работают профессионалы',
                    [SexType.Female]: 'Взять его с собой на встречу – пусть посмотрит, как работают профессионалы'
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Показать все ближайшие кафешки, познакомить с охранником\n' +
                    'на входе в офис и показать,\n' +
                    'как включается кофеварка',
                    [SexType.Female]: 'Показать все ближайшие кафешки, познакомить с охранником\n' +
                    'на входе в офис и показать,\n' +
                    'как включается кофеварка'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '9',
        question: 'Все супер!',
        text: {
            [SexType.Male]:'Стажер успешно справляется с поставленными задачами, рабочий день близится к концу. По твоему мнению, все проходит на высшем уровне. Остался маленький штрих – это: ',
            [SexType.Female]: 'Стажер успешно справляется с поставленными задачами, рабочий день близится к концу. По твоему мнению, все проходит на высшем уровне. Остался маленький штрих – это: ',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Надо наладить контакт со стажером, можно посидеть поболтать парочку часов после работы',
                    [SexType.Female]: 'Надо наладить контакт со стажером, можно посидеть поболтать парочку часов после работы'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Узнать у стажера, какие задачи вызывают сложности и ответить на возникшие вопросы',
                    [SexType.Female]: 'Узнать у стажера, какие задачи вызывают сложности и ответить на возникшие вопросы'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Убежать сразу после окончания рабочего дня',
                    [SexType.Female]: 'Убежать сразу после окончания рабочего дня'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '10',
        question: 'Первый день закончен!',
        text: {
            [SexType.Male]:'Не зря же именно тебя выделили руководители подразделения как эксперта своего дела. Чувствуешь гордость за себя и проделанную работу. Ну, а теперь осталось продумать план на завтрашний рабочий день: ',
            [SexType.Female]: 'Не зря же именно тебя выделили руководители подразделения как эксперта своего дела. Чувствуешь гордость за себя и проделанную работу. Ну, а теперь осталось продумать план на завтрашний рабочий день: ',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Надо будет обсудить со стажером все задачи, которые будут стоять перед ним, составить план стажировки. После этого можно отдохнуть и посмотреть Netflix',
                    [SexType.Female]: 'Надо будет обсудить со стажером все задачи, которые будут стоять перед ним, составить план стажировки. После этого можно отдохнуть и посмотреть Netflix'
                },
                isCorrect: true,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Утро вечера мудренее, завтра по ходу дела разберусь',
                    [SexType.Female]: 'Утро вечера мудренее, завтра по ходу дела разберусь'
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Считаю, что все обязанности выполнены: стажер знает, как работает компания изнутри',
                    [SexType.Female]: 'Считаю, что все обязанности выполнены: стажер знает, как работает компания изнутри'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '11',
        question: 'А вот и проблема',
        text: {
            [SexType.Male]:'Ты заметил, что таблицы в Excel стажер заполняет долго и иногда допускает неточности. Зато его не остановить, когда нужно придумать новую концепцию проекта. Сначала ты расстроился, но потом кое-что понял…',
            [SexType.Female]: 'Ты заметила, что таблицы в Excel стажер заполняет долго и иногда допускает неточности. Зато его не остановить, когда нужно придумать новую концепцию проекта. Сначала ты расстроилась, но потом кое-что поняла…',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Стажер никуда не годится, он плохой работник!',
                    [SexType.Female]: 'Стажер никуда не годится, он плохой работник!'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'На обучении нам рассказывали, что есть разные типы учащихся, скорее всего мой стажер – теоретик. Проверю гипотезу: переведу его на блок работы, где нужно креативить',
                    [SexType.Female]: 'На обучении нам рассказывали, что есть разные типы учащихся, скорее всего мой стажер – теоретик. Проверю гипотезу: переведу его на блок работы, где нужно креативить'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Надо нагрузить заданиями в Excel: чем больше практики, тем меньше ошибок',
                    [SexType.Female]: 'Надо нагрузить заданиями в Excel: чем больше практики, тем меньше ошибок'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '12',
        question: 'Ой-ой-ой',
        text: {
            [SexType.Male]:'Стажер делает явные успехи, проходит тренинги, задает интересные вопросы, на которые ты охотно отвечаешь. Но все же ты замечаешь, что энтузиазм слегка гаснет, что же делать?',
            [SexType.Female]:'Стажер делает явные успехи, проходит тренинги, задает интересные вопросы, на которые ты охотно отвечаешь. Но все же ты замечаешь, что энтузиазм слегка гаснет, что же делать?',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Станцую, конечно, это всегда помогает. По крайней мере, мне точно',
                    [SexType.Female]: 'Станцую, конечно, это всегда помогает. По крайней мере, мне точно'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Поговорю со стажером. Возможно, есть сложности с выполнением заданий и загрузкой',
                    [SexType.Female]: 'Поговорю со стажером. Возможно, есть сложности с выполнением заданий и загрузкой'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Перестану давать ему новые задачи – пускай отдыхает',
                    [SexType.Female]: 'Перестану давать ему новые задачи – пускай отдыхает'
                },
                isCorrect: false,
            },
            {
                id: '4',
                text: {
                    [SexType.Male]: 'Обсудим за чашкой кофе, какие задачи кажутся ему интересными. Подключу стажера к работе в новом проекте! ',
                    [SexType.Female]: 'Обсудим за чашкой кофе, какие задачи кажутся ему интересными. Подключу стажера к работе в новом проекте! '
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '13',
        question: 'Прошел первый месяц',
        text: {
            [SexType.Male]:'Ты доволен пройденным путем и почти уверен, что твой стажер – будущее компании. Улыбнувшись, ты берешься заполнять документы по обратной связи. Что обязательно укажешь?',
            [SexType.Female]: 'Ты довольна пройденным путем и почти уверен, что твой стажер – будущее компании. Улыбнувшись, ты берешься заполнять документы по обратной связи. Что обязательно укажешь?',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Позитивный настрой и\nуровень общения с коллективом. Надо \n' +
                    'показать все с лучшей стороны',
                    [SexType.Female]: 'Позитивный настрой и\nуровень общения с коллективом. Надо \n' +
                    'показать все с лучшей стороны'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Количество выполненных заданий – это главный показатель эффективности',
                    [SexType.Female]: 'Количество выполненных заданий – это главный показатель эффективности'
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Сильные стороны стажера, запишу все личные впечатления стажера от работы и укажу его предпочтения',
                    [SexType.Female]: 'Сильные стороны стажера, запишу все личные впечатления стажера от работы и укажу его предпочтения'
                },
                isCorrect: true,
            },
            {
                id: '4',
                text: {
                    [SexType.Male]: 'Свое мнение про стажера: насколько приятно мне c ним работать, понимает ли он то, что я пытаюсь донести ',
                    [SexType.Female]: 'Свое мнение про стажера: насколько приятно мне c ним работать, понимает ли он то, что я пытаюсь донести '
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '14',
        question: 'Ошибки',
        text: {
            [SexType.Male]:'Все мы их совершаем, и стажеры – далеко не исключение. Ты заметил, что твой стажер во время заполнения данных случайно ошибся. Как лучше сообщить стажеру про неточность? ',
            [SexType.Female]: 'Все мы их совершаем, и стажеры – далеко не исключение. Ты заметила, что твой стажер во время заполнения данных случайно ошибся. Как лучше сообщить стажеру про неточность? ',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Это работа – здесь не терпят оплошностей',
                    [SexType.Female]: 'Это работа – здесь не терпят оплошностей'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Расскажу, насколько важно\n' +
                    'для компании и бизнеса \nсоблюдать точность данных',
                    [SexType.Female]: 'Расскажу, насколько важно\n' +
                    'для компании и бизнеса \nсоблюдать точность данных',
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Нет времени объяснять – исправлю все самостоятельно',
                    [SexType.Female]: 'Нет времени объяснять – исправлю все самостоятельно'
                },
                isCorrect: false,
            },
            {
                id: '4',
                text: {
                    [SexType.Male]: 'Укажу на ошибку и поинтересуюсь мнением стажера: что нужно сделать, чтобы ситуация не повторилась',
                    [SexType.Female]: 'Укажу на ошибку и поинтересуюсь мнением стажера: что нужно сделать, чтобы ситуация не повторилась'
                },
                isCorrect: true,
            },

        ],
    },
    {
        id: '15',
        question: 'Ваш план работы на период стажировки идеален',
        text: {
            [SexType.Male]:'Так вы считали. Но в один момент ты замечаешь, что коллега не успевает доделывать свои задания, засиживается на работе, да и просто устало выглядит. Как ты поступишь? ',
            [SexType.Female]: 'Так вы считали. Но в один момент ты замечаешь, что коллега не успевает доделывать свои задания, засиживается на работе, да и просто устало выглядит. Как ты поступишь? ',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Безусловно, обсудим график и общую нагрузку, внесём правки. Важно быть всегда отдохнувшим и продуктивным!',
                    [SexType.Female]: 'Безусловно, обсудим график и общую нагрузку, внесём правки. Важно быть всегда отдохнувшим и продуктивным!'
                },
                isCorrect: true,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Буду делать работу вместе с ним, разделим задачи. Так будет быстрее',
                    [SexType.Female]: 'Буду делать работу вместе с ним, разделим задачи. Так будет быстрее'
                },
                isCorrect: false,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'У меня как раз есть хорошие патчи! Нет видимых признаков усталости, значит, ее и нет! ',
                    [SexType.Female]: 'У меня как раз есть хорошие патчи! Нет видимых признаков усталости, значит, ее и нет! '
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '16',
        question: 'Ура! Длинный путь пройден',
        text: {
            [SexType.Male]:'Заканчивается стажировка. Не без ма-а-аленьких сложностей...  Ты был всегда рядом и направлял усилия стажера в правильное русло.  А сейчас: ',
            [SexType.Female]: 'Заканчивается стажировка. Не без ма-а-аленьких сложностей...  Ты была всегда рядом и направляла усилия стажера в правильное русло. А сейчас:  ',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Заполню обратную связь и обязательно порекомендую стажера',
                    [SexType.Female]: 'Заполню обратную связь и обязательно порекомендую стажера'
                },
                isCorrect: false,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Проанализирую все выполненные стажером проекты, оценю нашу с ним полезность для компании',
                    [SexType.Female]: 'Проанализирую все выполненные стажером проекты, оценю нашу с ним полезность для компании'
                },
                isCorrect: true,
            },
            {
                id: '3',
                text: {
                    [SexType.Male]: 'Погрущу, что «птенчик совсем вырос», напишу отчет об итогах и снова в путь – давайте следующего!',
                    [SexType.Female]: 'Погрущу, что «птенчик совсем вырос», напишу отчет об итогах и снова в путь – давайте следующего!'
                },
                isCorrect: true,
            },
            {
                id: '4',
                text: {
                    [SexType.Male]: 'Не знал, что придется пройти такой сложный отбор, а потом еще так много вкладываться в сотрудника. Напишу рекомендацию, но пока не готов браться за новых стажеров',
                    [SexType.Female]: 'Не знала, что придется пройти такой сложный отбор, а потом еще так много вкладываться в сотрудника. Напишу рекомендацию, но пока не готова браться за новых стажеров'
                },
                isCorrect: false,
            },

        ],
    },
    {
        id: '17',
        question: 'Отличная работа!',
        text: {
            [SexType.Male]:'Ты проявил себя, «вырастил»\n' +
            'из стажера отличного сотрудника.\n' +
            '\n' +
            'Как оценишь свою работу?\n' +
            'Прошёл бы этот путь снова?:)',
            [SexType.Female]: 'Ты проявила себя, «вырастила»\n' +
            'из стажера отличного сотрудника.\n' +
            '\n' +
            'Как оценишь свою работу?\n' +
            'Прошла бы этот путь снова?:)',
        },
        answers: [
            {
                id: '1',
                text: {
                    [SexType.Male]: 'Считаю, что быть наставником – это мое!',
                    [SexType.Female]: 'Считаю, что быть наставником – это мое!'
                },
                isCorrect: true,
            },
            {
                id: '2',
                text: {
                    [SexType.Male]: 'Наставничество – это не моё, но на стажировку я бы хотел попасть!',
                    [SexType.Female]: 'Наставничество – это не моё, но на стажировку я бы хотела попасть!'
                },
                isCorrect: false,
            },

        ],
    },

];