import React from "react";

export const Section2ImgFemale = (props) => {
    return <svg {...props} viewBox="0 0 139 266" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip5)">
            <path d="M75.4775 90.7564C74.8968 90.7562 74.3218 90.8706 73.7853 91.093C73.2489 91.3154 72.7614 91.6415 72.3509 92.0526C71.9403 92.4636 71.6147 92.9516 71.3926 93.4887C71.1706 94.0258 71.0564 94.6014 71.0567 95.1827C71.0567 94.0089 70.591 92.8832 69.7619 92.0532C68.9329 91.2231 67.8085 90.7566 66.6359 90.7564C67.2165 90.7565 67.7914 90.6421 68.3278 90.4198C68.8642 90.1974 69.3516 89.8715 69.7622 89.4605C70.1727 89.0496 70.4983 88.5617 70.7205 88.0247C70.9426 87.4877 71.0568 86.9122 71.0567 86.3311C71.0563 86.9123 71.1704 87.4879 71.3924 88.025C71.6144 88.5621 71.94 89.0501 72.3506 89.4611C72.7612 89.8721 73.2487 90.1981 73.7853 90.4203C74.3218 90.6426 74.8969 90.7568 75.4775 90.7564Z" fill="white"/>
            <path d="M33.7591 93.6928C33.4502 93.6927 33.1444 93.7535 32.859 93.8718C32.5736 93.9901 32.3143 94.1635 32.096 94.3822C31.8776 94.6008 31.7045 94.8605 31.5864 95.1462C31.4684 95.4319 31.4077 95.7381 31.408 96.0473C31.408 95.4228 31.1602 94.824 30.7191 94.3824C30.278 93.9409 29.6797 93.6928 29.0559 93.6928C29.3648 93.6931 29.6707 93.6324 29.9561 93.5142C30.2415 93.3961 30.5009 93.2227 30.7193 93.0042C30.9378 92.7856 31.1111 92.526 31.2292 92.2404C31.3474 91.9547 31.4082 91.6485 31.408 91.3394C31.4076 91.6485 31.4681 91.9548 31.5862 92.2405C31.7042 92.5262 31.8773 92.7858 32.0957 93.0044C32.3141 93.223 32.5735 93.3964 32.8589 93.5145C33.1443 93.6326 33.4502 93.6932 33.7591 93.6928Z" fill="white"/>
            <path d="M61.1094 75.3334C60.4859 75.3337 59.8881 75.5817 59.4472 76.023C59.0064 76.4643 58.7586 77.0628 58.7583 77.6869C58.758 77.0628 58.5102 76.4643 58.0694 76.023C57.6285 75.5817 57.0307 75.3337 56.4072 75.3334C57.0307 75.3331 57.6285 75.0851 58.0694 74.6438C58.5102 74.2025 58.758 73.6041 58.7583 72.98C58.7586 73.6041 59.0064 74.2025 59.4472 74.6438C59.8881 75.0851 60.4859 75.3331 61.1094 75.3334Z" fill="white"/>
            <path d="M31.407 254.985H53.8307V149.34H42.6183L31.407 254.985Z" fill="#101010"/>
            <path d="M87.9156 254.985H65.4919L58.991 149.34H70.2023L87.9156 254.985Z" fill="#101010"/>
            <path d="M21.3076 266H53.8306V254.985H31.407L21.3076 266Z" fill="#101010"/>
            <path d="M98.0149 266H65.4919V254.985H87.9155L98.0149 266Z" fill="#101010"/>
            <path d="M53.8307 247.051H32.2717C32.2169 247.051 32.1642 247.03 32.1254 246.991C32.0866 246.952 32.0648 246.899 32.0648 246.844C32.0648 246.789 32.0866 246.737 32.1254 246.698C32.1642 246.659 32.2169 246.637 32.2717 246.637H53.8307C53.8855 246.637 53.9381 246.659 53.9769 246.698C54.0157 246.737 54.0375 246.789 54.0375 246.844C54.0375 246.899 54.0157 246.952 53.9769 246.991C53.9381 247.03 53.8855 247.051 53.8307 247.051Z" fill="white"/>
            <path d="M86.5513 247.051H65.9253C65.8704 247.051 65.8178 247.03 65.779 246.991C65.7402 246.952 65.7184 246.899 65.7184 246.844C65.7184 246.789 65.7402 246.737 65.779 246.698C65.8178 246.659 65.8704 246.637 65.9253 246.637H86.5513C86.6062 246.637 86.6588 246.659 86.6976 246.698C86.7364 246.737 86.7582 246.789 86.7582 246.844C86.7582 246.899 86.7364 246.952 86.6976 246.991C86.6588 247.03 86.6062 247.051 86.5513 247.051Z" fill="white"/>
            <path d="M71.0567 115.031H40.3562C38.7726 133.941 37.9679 162.19 37.9679 162.19H85.038L71.0567 115.031Z" fill="#E52322"/>
            <path d="M44.624 109.588C46.4662 108.736 47.1715 106.338 46.1996 104.231C45.2276 102.125 42.9463 101.107 41.1042 101.959C39.262 102.811 38.5567 105.209 39.5287 107.315C40.5006 109.422 42.7819 110.439 44.624 109.588Z" fill="white"/>
            <path d="M68.238 104.148C68.4836 101.841 67.047 99.796 65.0293 99.5809C63.0116 99.3657 61.1769 101.062 60.9314 103.369C60.6858 105.677 62.1224 107.722 64.1401 107.937C66.1578 108.152 67.9925 106.456 68.238 104.148Z" fill="white"/>
            <path d="M60.5129 110.436C64.3443 106.601 64.3443 100.383 60.5129 96.5479C56.6815 92.7127 50.4697 92.7127 46.6383 96.5479C42.807 100.383 42.807 106.601 46.6383 110.436C50.4697 114.272 56.6815 114.272 60.5129 110.436Z" fill="white"/>
            <path d="M52.9297 93.6929C50.7107 93.8404 48.6075 94.7378 46.9646 96.2381C45.3216 97.7384 44.2363 99.7528 43.8864 101.951C43.9081 101.943 51.914 98.844 52.9297 93.6929Z" fill="#101010"/>
            <path d="M62.1086 98.649C61.1993 97.0427 59.8559 95.7256 58.2327 94.8489C56.6095 93.9722 54.7723 93.5714 52.9318 93.6926C52.9577 93.7195 58.0219 98.8861 62.1086 98.649Z" fill="#101010"/>
            <path d="M49.7569 105.591C50.584 104.82 50.6297 103.523 49.8589 102.695C49.0882 101.868 47.7929 101.822 46.9659 102.593C46.1388 103.365 46.0931 104.661 46.8638 105.489C47.6346 106.317 48.9298 106.363 49.7569 105.591Z" fill="#E52322"/>
            <path d="M61.4472 105.116C61.8785 104.07 61.3811 102.872 60.3361 102.44C59.2911 102.008 58.0943 102.506 57.6629 103.552C57.2316 104.598 57.729 105.796 58.774 106.228C59.819 106.66 61.0158 106.162 61.4472 105.116Z" fill="#E52322"/>
            <path d="M54.0789 104.271C54.0316 104.271 53.9856 104.255 53.9486 104.225C53.9116 104.196 53.8859 104.154 53.8757 104.108C53.8656 104.062 53.8716 104.013 53.8927 103.971C53.9139 103.929 53.9489 103.895 53.992 103.875L55.4287 103.211C54.9281 102.858 53.932 102.13 53.4262 101.574C53.408 101.554 53.3939 101.53 53.3848 101.504C53.3757 101.479 53.3718 101.451 53.3733 101.424C53.3748 101.396 53.3816 101.37 53.3935 101.345C53.4053 101.32 53.4219 101.298 53.4423 101.28C53.4626 101.262 53.4864 101.247 53.5122 101.238C53.538 101.229 53.5654 101.225 53.5927 101.227C53.62 101.228 53.6468 101.235 53.6715 101.247C53.6962 101.259 53.7183 101.276 53.7365 101.296C54.3809 102.004 55.948 103.065 55.9635 103.076C55.9938 103.096 56.0181 103.125 56.034 103.158C56.0498 103.191 56.0566 103.227 56.0537 103.264C56.0507 103.3 56.0381 103.335 56.0172 103.366C55.9963 103.396 55.9677 103.42 55.9345 103.435L54.1689 104.252C54.1407 104.265 54.1099 104.271 54.0789 104.271Z" fill="#101010"/>
            <path d="M54.503 106.085C51.8861 106.662 51.2241 104.682 51.9616 103.496L54.503 106.085Z" fill="#101010"/>
            <path d="M51.4734 101.138C51.4325 101.25 51.3595 101.346 51.2638 101.416C51.168 101.486 51.0537 101.526 50.9353 101.531C50.8169 101.535 50.6997 101.505 50.5987 101.443C50.4976 101.381 50.4172 101.291 50.3675 101.183C50.3179 101.075 50.3012 100.955 50.3197 100.838C50.3382 100.721 50.391 100.612 50.4714 100.525C50.5518 100.438 50.6562 100.376 50.7714 100.349C50.8866 100.321 51.0074 100.328 51.1186 100.369C51.2675 100.424 51.3885 100.536 51.455 100.68C51.5215 100.825 51.5281 100.989 51.4734 101.138Z" fill="#101010"/>
            <path d="M58.0488 100.924C58.0079 101.035 57.9349 101.132 57.8391 101.202C57.7433 101.272 57.629 101.311 57.5107 101.316C57.3923 101.321 57.2751 101.29 57.1741 101.228C57.073 101.167 56.9926 101.076 56.9429 100.968C56.8932 100.861 56.8766 100.741 56.8951 100.624C56.9136 100.506 56.9664 100.397 57.0468 100.31C57.1272 100.223 57.2316 100.162 57.3468 100.134C57.462 100.107 57.5828 100.114 57.694 100.155C57.7678 100.182 57.8355 100.223 57.8933 100.277C57.9511 100.33 57.9978 100.394 58.0308 100.466C58.0637 100.537 58.0823 100.614 58.0854 100.693C58.0885 100.772 58.076 100.85 58.0488 100.924Z" fill="#101010"/>
            <path d="M43.7871 107.902C43.7559 107.902 43.7251 107.896 43.697 107.882C43.6689 107.869 43.6442 107.849 43.6247 107.825C43.183 107.271 40.9716 104.497 40.8237 104.212C40.7983 104.163 40.7933 104.107 40.8098 104.054C40.8263 104.002 40.8629 103.958 40.9116 103.933C40.9603 103.907 41.0171 103.902 41.0695 103.919C41.1218 103.935 41.1655 103.972 41.1909 104.021C41.2943 104.219 42.9379 106.299 43.9485 107.566C43.9728 107.596 43.9881 107.633 43.9925 107.672C43.9969 107.711 43.9903 107.75 43.9735 107.785C43.9566 107.82 43.9302 107.85 43.8972 107.871C43.8642 107.891 43.8261 107.902 43.7871 107.902Z" fill="#101010"/>
            <path d="M64.2662 105.129C64.2378 105.129 64.2096 105.123 64.1835 105.112C64.1339 105.09 64.0952 105.048 64.0758 104.997C64.0565 104.946 64.058 104.89 64.08 104.84L65.4981 101.573C65.5201 101.523 65.5611 101.483 65.6122 101.463C65.6634 101.443 65.7204 101.444 65.7707 101.466C65.821 101.488 65.8606 101.529 65.8807 101.58C65.9008 101.631 65.8997 101.688 65.8777 101.739L64.4586 105.004C64.4423 105.042 64.4154 105.074 64.3811 105.096C64.3469 105.118 64.3069 105.129 64.2662 105.129Z" fill="#101010"/>
            <path d="M71.0567 115.031L71.608 114.942C96.2514 113.111 93.5114 83.084 93.5114 83.084L110.396 83.3221C110.396 83.3221 119.533 122.758 88.2166 134.429L76.456 137.374L71.0567 115.031Z" fill="#E52322"/>
            <path d="M50.2497 92.9409L36.2043 53.5059L29.7799 58.2625L32.5572 63.9664L27.6306 59.8539L22.8188 63.4156L24.4075 66.5829L20.3187 65.2669L16.8857 67.8078L50.2497 92.9409Z" fill="#101010"/>
            <path d="M107.724 70.5313C107.224 70.5488 106.741 70.7229 106.344 71.0291C105.947 71.3353 105.656 71.7583 105.511 72.2386L105.468 71.0189C105.44 70.387 105.164 69.7917 104.7 69.3624C104.236 68.9332 103.621 68.7046 102.989 68.7265C102.358 68.7483 101.76 69.0187 101.327 69.479C100.893 69.9393 100.659 70.5522 100.674 71.1846L100.606 65.58C100.543 64.9611 100.243 64.3907 99.768 63.9891C99.2933 63.5875 98.6814 63.386 98.0612 63.4271C97.4411 63.4682 96.8611 63.7486 96.4434 64.2093C96.0257 64.67 95.803 65.275 95.8221 65.8968L96.1148 78.158L95.1777 76.784C94.8307 76.2915 94.3041 75.9555 93.7117 75.8485C93.1193 75.7414 92.5085 75.872 92.0115 76.2119C91.5144 76.5519 91.1709 77.074 91.0552 77.6653C90.9395 78.2567 91.0608 78.8699 91.393 79.3725L93.6686 83.0864C93.6686 83.0864 108.271 83.2583 110.391 83.3215L110.2 72.8474C110.177 72.2121 109.904 71.6115 109.44 71.1773C108.976 70.7432 108.359 70.5109 107.724 70.5313Z" fill="white"/>
            <path d="M103.009 79.3817C102.961 79.3817 102.915 79.3817 102.867 79.3817C102.19 79.349 101.552 79.0538 101.089 78.5587C100.626 78.0636 100.373 77.4073 100.385 76.729L100.463 71.1845C100.464 71.1295 100.487 71.0772 100.526 71.0389C100.565 71.0007 100.618 70.9797 100.673 70.9805C100.728 70.9813 100.78 71.0039 100.819 71.0433C100.857 71.0828 100.878 71.1358 100.877 71.1907L100.798 76.7352C100.788 77.3199 101.01 77.8846 101.416 78.3053C101.822 78.726 102.378 78.9681 102.962 78.9784C103.546 78.9887 104.11 78.7663 104.53 78.3602C104.95 77.9541 105.192 77.3975 105.203 76.8129L105.3 72.2375C105.301 72.1825 105.324 72.1304 105.364 72.0924C105.403 72.0544 105.456 72.0338 105.511 72.035C105.566 72.0363 105.618 72.0593 105.656 72.099C105.694 72.1387 105.715 72.1919 105.714 72.2468L105.616 76.8222C105.601 77.5061 105.32 78.1571 104.832 78.636C104.344 79.1149 103.688 79.3836 103.005 79.3848L103.009 79.3817Z" fill="#101010"/>
            <path d="M107.854 78.6353C107.806 78.6353 107.76 78.6353 107.712 78.6353C107.035 78.6028 106.397 78.3078 105.934 77.8129C105.471 77.3179 105.218 76.6618 105.23 75.9836L105.3 72.2396C105.3 72.2124 105.306 72.1856 105.317 72.1607C105.328 72.1357 105.344 72.1131 105.363 72.0943C105.383 72.0754 105.406 72.0605 105.431 72.0505C105.456 72.0406 105.483 72.0357 105.51 72.0362C105.538 72.0366 105.564 72.0425 105.589 72.0533C105.614 72.0642 105.637 72.0798 105.656 72.0994C105.675 72.1189 105.689 72.142 105.699 72.1673C105.709 72.1927 105.714 72.2197 105.714 72.2469L105.643 75.9898C105.633 76.5745 105.855 77.1392 106.261 77.5599C106.667 77.9806 107.223 78.2227 107.807 78.233C108.391 78.2433 108.955 78.0209 109.375 77.6148C109.795 77.2087 110.037 76.6521 110.048 76.0675L109.998 73.2088C109.997 73.1538 110.018 73.1008 110.056 73.0613C110.094 73.0218 110.146 72.999 110.201 72.9981C110.256 72.9971 110.309 73.018 110.349 73.0561C110.388 73.0943 110.411 73.1466 110.412 73.2015L110.461 76.0685C110.449 76.7539 110.168 77.407 109.68 77.8876C109.191 78.3683 108.534 78.6382 107.85 78.6394L107.854 78.6353Z" fill="#101010"/>
            <path d="M50.2559 93.2353C50.2128 93.2355 50.1701 93.2267 50.1306 93.2094C50.0911 93.1921 50.0556 93.1668 50.0264 93.1351C49.9972 93.1033 49.975 93.0658 49.9611 93.0249C49.9471 92.9841 49.9419 92.9408 49.9456 92.8978C50.0951 91.3368 50.3718 89.7906 50.7731 88.2748C51.6461 85.1033 52.8977 83.5596 54.4968 83.6859C55.6956 83.7822 56.52 84.5235 56.702 85.6707C56.8841 86.8179 56.4124 88.2354 55.406 89.5514C54.6054 90.5982 53.0828 92.0829 50.3769 93.2115C50.3386 93.2273 50.2974 93.2354 50.2559 93.2353ZM54.3261 84.3009C52.0071 84.3009 50.9127 89.7305 50.6169 92.4277C52.8925 91.3851 54.2051 90.097 54.9116 89.1735C55.8145 87.9931 56.2428 86.7517 56.0866 85.7681C55.949 84.8983 55.3625 84.3786 54.4461 84.305C54.4058 84.3019 54.3654 84.3009 54.3261 84.3009Z" fill="white"/>
            <path d="M43.8099 95.6207C42.5687 95.6207 41.4878 95.3028 40.7689 94.7095C39.8731 93.9703 39.6528 92.8831 40.1793 91.8001C40.8785 90.3609 42.8428 90.0647 46.0162 90.921C47.5248 91.3431 48.9965 91.8882 50.4163 92.5507C50.4645 92.5737 50.506 92.6088 50.5367 92.6525C50.5675 92.6962 50.5864 92.7472 50.5917 92.8004C50.597 92.8536 50.5885 92.9073 50.567 92.9562C50.5455 93.0052 50.5118 93.0478 50.469 93.0798C48.1252 94.84 46.0741 95.4012 44.7667 95.5648C44.4492 95.6029 44.1296 95.6216 43.8099 95.6207ZM42.9824 91.0712C41.9274 91.0712 41.0875 91.3445 40.7337 92.0724C40.3303 92.9007 40.4813 93.67 41.1599 94.2301C41.9274 94.8638 43.2131 95.1237 44.688 94.9446C45.8424 94.8038 47.6142 94.3233 49.6684 92.8903C48.0011 92.1417 45.0718 91.0712 42.9855 91.0712H42.9824Z" fill="white"/>
            <path d="M40.3562 115.031C40.3562 115.031 5.60205 115.031 0.386858 149.34C-4.46527 181.262 37.9679 196.606 37.9679 196.606L40.3541 170.665C29.1014 169.681 13.2128 146.654 38.8864 138.611L40.3562 115.031Z" fill="#E52322"/>
            <path d="M50.9686 182.246C51.02 181.722 50.9049 181.195 50.6397 180.741C50.3744 180.287 49.9726 179.928 49.4916 179.715C50.0712 179.587 50.5859 179.255 50.9433 178.78C51.3008 178.306 51.4775 177.719 51.4417 177.126C51.4059 176.533 51.1601 175.972 50.7482 175.543C50.3364 175.115 49.7856 174.848 49.1947 174.79L37.7931 173.664C37.4668 173.631 37.1373 173.664 36.8235 173.759C36.5097 173.854 36.2178 174.01 35.9643 174.218C35.7108 174.426 35.5007 174.683 35.3462 174.972C35.1916 175.261 35.0955 175.579 35.0634 175.905C34.9548 177.007 34.7066 179.529 34.5969 180.629C34.4625 182.003 34.2421 184.229 34.1066 185.598C34.0417 186.258 34.2412 186.916 34.6611 187.429C35.081 187.941 35.6871 188.266 36.346 188.331L47.7487 189.457C48.391 189.492 49.022 189.278 49.5103 188.859C49.9985 188.44 50.3064 187.848 50.3696 187.207C50.4328 186.567 50.2465 185.926 49.8496 185.419C49.4527 184.913 48.8757 184.579 48.239 184.488C48.8979 184.552 49.5556 184.353 50.0675 183.932C50.5794 183.512 50.9035 182.905 50.9686 182.246Z" fill="white"/>
            <path d="M49.4916 179.922H49.4709L43.662 179.349C43.6071 179.343 43.5566 179.316 43.5217 179.273C43.4868 179.231 43.4703 179.176 43.4758 179.121C43.4813 179.066 43.5083 179.016 43.551 178.981C43.5937 178.946 43.6485 178.929 43.7033 178.935L49.5123 179.508C49.5394 179.51 49.5661 179.516 49.5906 179.528C49.6152 179.54 49.6373 179.556 49.6555 179.576C49.6737 179.596 49.6878 179.62 49.697 179.646C49.7061 179.671 49.7101 179.698 49.7088 179.726C49.7074 179.753 49.7007 179.78 49.6891 179.804C49.6774 179.829 49.661 179.851 49.6409 179.869C49.6207 179.887 49.5971 179.901 49.5715 179.911C49.5459 179.92 49.5187 179.924 49.4916 179.922Z" fill="#101010"/>
            <path d="M48.239 184.695H48.2183L44.0023 184.28C43.9474 184.275 43.8969 184.248 43.8619 184.205C43.8269 184.163 43.8102 184.108 43.8156 184.053C43.8209 183.998 43.8478 183.948 43.8904 183.913C43.933 183.878 43.9877 183.861 44.0426 183.866L48.2597 184.28C48.2868 184.282 48.3135 184.288 48.338 184.3C48.3626 184.312 48.3847 184.328 48.4029 184.348C48.4212 184.369 48.4353 184.392 48.4444 184.418C48.4535 184.443 48.4575 184.471 48.4562 184.498C48.4548 184.525 48.4481 184.552 48.4365 184.576C48.4248 184.601 48.4085 184.623 48.3883 184.641C48.3681 184.659 48.3445 184.674 48.3189 184.683C48.2933 184.692 48.2661 184.696 48.239 184.695Z" fill="#101010"/>
            <path d="M42.5459 175.313L42.9131 171.222C42.9394 170.93 42.9067 170.635 42.8171 170.356C42.7275 170.077 42.5827 169.819 42.3914 169.596C42.2 169.374 41.9661 169.193 41.7033 169.063C41.4406 168.933 41.1545 168.858 40.862 168.841C40.2988 168.808 39.745 168.995 39.3159 169.361C38.8869 169.728 38.6159 170.246 38.5595 170.808L38.153 174.879L42.5459 175.313Z" fill="white"/>
            <path d="M88.802 258.924C88.748 258.924 88.6949 258.91 88.648 258.884C88.601 258.857 88.5619 258.818 88.5344 258.772C88.507 258.725 88.4921 258.672 88.4913 258.618C88.4905 258.564 88.5038 258.511 88.5299 258.464C88.9555 257.706 89.4274 256.976 89.9428 256.277C91.0672 254.762 92.0612 253.95 92.8949 253.863C93.3686 253.814 93.7958 253.996 94.1361 254.389C94.5581 254.882 94.5374 255.348 94.4464 255.653C93.9127 257.428 89.4029 258.765 88.8899 258.912C88.8613 258.92 88.8317 258.925 88.802 258.924ZM93.0428 254.472C93.0134 254.473 92.9841 254.474 92.9549 254.477C92.3198 254.543 91.4271 255.306 90.4404 256.638C90.0556 257.156 89.7163 257.674 89.4774 258.067C89.9335 257.913 90.5438 257.691 91.1644 257.421C92.7159 256.744 93.6696 256.051 93.8444 255.469C93.8879 255.322 93.9168 255.092 93.6572 254.79C93.5859 254.695 93.494 254.617 93.3882 254.563C93.2825 254.508 93.1658 254.479 93.0469 254.476L93.0428 254.472Z" fill="white"/>
            <path d="M88.802 258.924H88.7803C83.7564 258.584 82.6073 256.929 82.3466 256.232C82.0798 255.521 82.2753 254.782 82.8969 254.161C83.0434 254.01 83.2229 253.896 83.4212 253.826C83.6195 253.756 83.8313 253.733 84.0399 253.76C84.9201 253.85 86.0372 254.674 87.3622 256.209C87.972 256.92 88.5405 257.665 89.0647 258.44C89.0958 258.487 89.1136 258.542 89.1162 258.598C89.1189 258.654 89.1062 258.71 89.0797 258.759C89.0532 258.809 89.0137 258.85 88.9655 258.879C88.9174 258.908 88.8623 258.923 88.8061 258.923L88.802 258.924ZM83.8754 254.376C83.7744 254.374 83.6742 254.393 83.5813 254.433C83.4883 254.472 83.4045 254.531 83.3355 254.604C83.0252 254.915 82.7014 255.412 82.9269 256.014C83.4172 257.321 85.85 258.011 88.1721 258.248C87.1832 256.867 85.248 254.51 83.9768 254.381C83.9426 254.378 83.9085 254.376 83.8754 254.376Z" fill="white"/>
            <path d="M31.8145 258.929C31.7603 258.929 31.7069 258.915 31.6599 258.888C31.6128 258.861 31.5737 258.822 31.5464 258.775C31.5191 258.728 31.5045 258.675 31.5042 258.62C31.5039 258.566 31.5178 258.513 31.5446 258.465C31.98 257.714 32.4615 256.99 32.9865 256.298C34.1304 254.798 35.1348 253.999 35.9706 253.923C36.4433 253.88 36.8694 254.067 37.2014 254.466C37.6152 254.964 37.5893 255.43 37.4942 255.732C36.9377 257.501 32.4103 258.778 31.8963 258.918C31.8697 258.926 31.8422 258.93 31.8145 258.929ZM36.104 254.538C36.0785 254.538 36.0526 254.538 36.0264 254.538C35.3903 254.596 34.4873 255.352 33.4829 256.667C33.0909 257.179 32.7496 257.692 32.5014 258.082C32.9596 257.934 33.5729 257.721 34.1967 257.461C35.7596 256.804 36.7205 256.123 36.9025 255.545C36.948 255.399 36.9801 255.169 36.7246 254.862C36.6536 254.764 36.5608 254.684 36.4535 254.627C36.3461 254.571 36.2272 254.541 36.1061 254.538H36.104Z" fill="white"/>
            <path d="M31.8145 258.929H31.7897C26.77 258.525 25.6426 256.853 25.3912 256.154C25.1337 255.439 25.3395 254.705 25.9694 254.094C26.1177 253.945 26.2986 253.832 26.4979 253.765C26.6971 253.698 26.9093 253.678 27.1175 253.707C27.9957 253.81 29.1025 254.647 30.4068 256.201C31.0048 256.918 31.5608 257.669 32.0721 258.451C32.1022 258.498 32.1191 258.552 32.121 258.608C32.123 258.664 32.1099 258.719 32.0831 258.768C32.0563 258.817 32.0168 258.858 31.9689 258.886C31.9209 258.915 31.8661 258.929 31.8104 258.929H31.8145ZM26.9334 254.317C26.8344 254.315 26.7361 254.334 26.6446 254.372C26.5532 254.41 26.4705 254.467 26.4018 254.538C26.0915 254.849 25.7574 255.338 25.9746 255.942C26.4483 257.256 28.8708 257.977 31.1908 258.245C30.2196 256.851 28.3153 254.469 27.0462 254.324C27.0088 254.319 26.9711 254.317 26.9334 254.317Z" fill="white"/>
            <path d="M70.1186 26.7246C70.0482 26.7249 69.9798 26.7012 69.9247 26.6575C69.8695 26.6138 69.8308 26.5527 69.815 26.4841C69.7991 26.4155 69.807 26.3435 69.8373 26.28C69.8677 26.2164 69.9187 26.1651 69.982 26.1344L74.3325 23.9994C74.4064 23.9632 74.4917 23.9578 74.5696 23.9845C74.6475 24.0112 74.7116 24.0678 74.7478 24.1418C74.784 24.2158 74.7893 24.3012 74.7627 24.3792C74.736 24.4571 74.6795 24.5213 74.6056 24.5575L70.2551 26.6925C70.2126 26.7135 70.1659 26.7245 70.1186 26.7246Z" fill="white"/>
            <path d="M76.8718 35.3731C76.8077 35.3735 76.745 35.3536 76.6929 35.3162C76.6259 35.2685 76.5805 35.1962 76.5667 35.115C76.5529 35.0339 76.5719 34.9506 76.6195 34.8834L79.4205 30.9261C79.4681 30.8588 79.5404 30.8132 79.6216 30.7994C79.7028 30.7855 79.7861 30.8044 79.8533 30.8521C79.9205 30.8997 79.9661 30.9722 79.98 31.0534C79.9938 31.1347 79.9749 31.2181 79.9273 31.2854L77.1252 35.2427C77.0965 35.2831 77.0585 35.3161 77.0144 35.3388C76.9703 35.3615 76.9214 35.3732 76.8718 35.3731Z" fill="white"/>
            <path d="M74.3118 17.1554C74.2688 17.1556 74.2261 17.1468 74.1867 17.1295L69.7503 15.1799C69.7115 15.1646 69.6761 15.1415 69.6464 15.1122C69.6167 15.0829 69.5932 15.0479 69.5773 15.0092C69.5614 14.9706 69.5534 14.9292 69.5539 14.8874C69.5544 14.8456 69.5633 14.8043 69.58 14.7661C69.5968 14.7278 69.6211 14.6934 69.6515 14.6647C69.6819 14.6361 69.7178 14.6139 69.757 14.5995C69.7961 14.585 69.8378 14.5786 69.8795 14.5807C69.9212 14.5828 69.962 14.5932 69.9996 14.6115L74.4359 16.5601C74.5013 16.5886 74.5549 16.6388 74.5876 16.7022C74.6203 16.7656 74.6303 16.8384 74.6157 16.9083C74.6011 16.9781 74.563 17.0409 74.5076 17.0859C74.4523 17.1309 74.3831 17.1554 74.3118 17.1554Z" fill="white"/>
            <path d="M79.2229 10.1136C79.1756 10.1136 79.1289 10.1028 79.0864 10.082C79.0439 10.0612 79.0068 10.0309 78.9777 9.9935L76.0122 6.16254C75.9853 6.13052 75.965 6.09337 75.9527 6.05333C75.9405 6.01328 75.9364 5.97116 75.9408 5.92951C75.9452 5.88785 75.958 5.84752 75.9784 5.81093C75.9988 5.77435 76.0263 5.74227 76.0594 5.71663C76.0925 5.69098 76.1304 5.6723 76.1709 5.6617C76.2114 5.65111 76.2536 5.64883 76.295 5.65499C76.3364 5.66115 76.3761 5.67562 76.4118 5.69754C76.4475 5.71947 76.4783 5.74838 76.5025 5.78255L79.468 9.61351C79.5182 9.67852 79.5406 9.76077 79.5303 9.84227C79.52 9.92377 79.4779 9.99788 79.4132 10.0484C79.3588 10.0907 79.2918 10.1136 79.2229 10.1136Z" fill="white"/>
            <path d="M138.447 26.7246C138.399 26.7245 138.353 26.7135 138.31 26.6925L133.955 24.5575C133.881 24.5213 133.825 24.4571 133.798 24.3792C133.772 24.3012 133.777 24.2158 133.813 24.1418C133.849 24.0678 133.913 24.0112 133.991 23.9845C134.069 23.9578 134.155 23.9632 134.228 23.9994L138.579 26.1344C138.642 26.1651 138.693 26.2164 138.724 26.28C138.754 26.3435 138.762 26.4155 138.746 26.4841C138.73 26.5527 138.691 26.6138 138.636 26.6575C138.581 26.7012 138.513 26.7249 138.442 26.7246H138.447Z" fill="white"/>
            <path d="M131.693 35.3731C131.644 35.3732 131.595 35.3614 131.551 35.3387C131.507 35.316 131.469 35.283 131.44 35.2426L128.639 31.2853C128.595 31.2181 128.578 31.1363 128.593 31.0573C128.608 30.9782 128.653 30.908 128.719 30.8615C128.784 30.8149 128.865 30.7957 128.945 30.8077C129.024 30.8197 129.096 30.8621 129.145 30.926L131.946 34.8833C131.993 34.9505 132.012 35.0338 131.998 35.1149C131.985 35.1961 131.939 35.2684 131.872 35.3161C131.82 35.3535 131.757 35.3734 131.693 35.3731Z" fill="white"/>
            <path d="M134.253 17.1554C134.182 17.1554 134.113 17.1309 134.058 17.0859C134.002 17.0409 133.964 16.9781 133.95 16.9083C133.935 16.8384 133.945 16.7656 133.978 16.7022C134.01 16.6388 134.064 16.5886 134.129 16.5601L138.566 14.6115C138.603 14.5932 138.644 14.5828 138.686 14.5807C138.727 14.5786 138.769 14.585 138.808 14.5995C138.847 14.6139 138.883 14.6361 138.914 14.6647C138.944 14.6934 138.968 14.7278 138.985 14.7661C139.002 14.8043 139.011 14.8456 139.011 14.8874C139.012 14.9292 139.004 14.9706 138.988 15.0092C138.972 15.0479 138.949 15.0829 138.919 15.1122C138.889 15.1415 138.854 15.1646 138.815 15.1799L134.379 17.1295C134.339 17.1468 134.296 17.1556 134.253 17.1554Z" fill="white"/>
            <path d="M129.342 10.1137C129.273 10.1137 129.206 10.0907 129.152 10.0484C129.087 9.99795 129.045 9.92384 129.035 9.84235C129.025 9.76085 129.047 9.6786 129.097 9.61358L132.063 5.78263C132.087 5.75033 132.119 5.72327 132.154 5.70298C132.189 5.6827 132.228 5.66958 132.269 5.66439C132.309 5.6592 132.35 5.66203 132.39 5.67273C132.429 5.68342 132.466 5.70176 132.498 5.72671C132.53 5.75166 132.557 5.78273 132.578 5.81814C132.598 5.85355 132.611 5.89261 132.616 5.93308C132.621 5.97356 132.619 6.01466 132.608 6.05405C132.597 6.09343 132.579 6.13032 132.554 6.16261L129.587 9.99357C129.558 10.031 129.521 10.0612 129.479 10.0821C129.436 10.1029 129.39 10.1137 129.342 10.1137Z" fill="white"/>
            <path d="M95.8377 45.4478H111.224V49.9538C111.224 51.996 110.413 53.9546 108.971 55.3987C107.528 56.8427 105.571 57.654 103.531 57.654C101.491 57.654 99.5344 56.8427 98.0918 55.3987C96.6492 53.9546 95.8387 51.996 95.8387 49.9538V45.4478H95.8377Z" fill="#00923A"/>
            <path d="M124.161 20.6509C124.161 9.24607 114.924 0 103.531 0C92.1378 0 82.9 9.24607 82.9 20.6509C82.8988 24.1921 83.8077 27.6741 85.5395 30.762C87.2713 33.8498 89.7676 36.4398 92.7884 38.2827C93.7228 38.8572 94.4941 39.6623 95.0286 40.6208C95.563 41.5793 95.8427 42.6591 95.8408 43.7568V45.4476H111.227V43.7568C111.225 42.6591 111.505 41.5794 112.039 40.6209C112.574 39.6625 113.345 38.8574 114.279 38.2827C117.299 36.4392 119.794 33.849 121.525 30.7612C123.256 27.6733 124.163 24.1916 124.161 20.6509Z" fill="white"/>
            <path d="M101.891 45.8618C101.791 45.8629 101.694 45.8279 101.618 45.7632C101.542 45.6985 101.492 45.6085 101.477 45.5097L98.0336 22.7849C98.0171 22.6763 98.0444 22.5656 98.1095 22.4771C98.1746 22.3887 98.2721 22.3298 98.3806 22.3133C98.4891 22.2968 98.5997 22.3241 98.688 22.3893C98.7764 22.4544 98.8353 22.552 98.8517 22.6607L102.304 45.3855C102.321 45.4939 102.293 45.6044 102.228 45.6928C102.163 45.7811 102.066 45.84 101.958 45.8566C101.936 45.86 101.913 45.8617 101.891 45.8618Z" fill="#101010"/>
            <path d="M105.171 45.8616C105.15 45.8632 105.129 45.8632 105.109 45.8616C105 45.8453 104.903 45.7865 104.837 45.6981C104.772 45.6098 104.745 45.4991 104.761 45.3905L108.21 22.6667C108.215 22.611 108.232 22.557 108.259 22.5079C108.285 22.4589 108.322 22.4158 108.366 22.3812C108.41 22.3467 108.461 22.3214 108.515 22.307C108.569 22.2925 108.625 22.2892 108.68 22.2972C108.736 22.3052 108.789 22.3243 108.837 22.3534C108.884 22.3826 108.926 22.4211 108.958 22.4666C108.991 22.5122 109.014 22.5639 109.025 22.6186C109.037 22.6733 109.038 22.7298 109.028 22.7848L105.58 45.5086C105.566 45.6068 105.516 45.6965 105.441 45.7613C105.366 45.8261 105.27 45.8617 105.171 45.8616Z" fill="#101010"/>
            <path d="M111.224 49.6379H95.8377C95.728 49.6379 95.6227 49.5943 95.5451 49.5166C95.4675 49.4389 95.424 49.3336 95.424 49.2237C95.424 49.1139 95.4675 49.0085 95.5451 48.9309C95.6227 48.8532 95.728 48.8096 95.8377 48.8096H111.224C111.333 48.8096 111.439 48.8532 111.516 48.9309C111.594 49.0085 111.637 49.1139 111.637 49.2237C111.637 49.3336 111.594 49.4389 111.516 49.5166C111.439 49.5943 111.333 49.6379 111.224 49.6379Z" fill="white"/>
            <path d="M99.1195 23.1381C97.8401 23.1367 96.6135 22.6274 95.7087 21.7219C94.8039 20.8164 94.2949 19.5887 94.2933 18.308C94.2933 18.1981 94.3369 18.0928 94.4144 18.0151C94.492 17.9374 94.5973 17.8938 94.707 17.8938C94.8167 17.8938 94.922 17.9374 94.9996 18.0151C95.0772 18.0928 95.1208 18.1981 95.1208 18.308C95.1376 19.3583 95.5663 20.3599 96.3143 21.0967C97.0623 21.8335 98.0696 22.2464 99.119 22.2464C100.168 22.2464 101.176 21.8335 101.924 21.0967C102.672 20.3599 103.1 19.3583 103.117 18.308C103.117 18.1981 103.161 18.0928 103.238 18.0151C103.316 17.9374 103.421 17.8938 103.531 17.8938C103.641 17.8938 103.746 17.9374 103.824 18.0151C103.901 18.0928 103.945 18.1981 103.945 18.308C103.943 19.5885 103.434 20.8161 102.53 21.7215C101.625 22.627 100.399 23.1364 99.1195 23.1381Z" fill="#101010"/>
            <path d="M107.943 23.1381C106.663 23.1364 105.437 22.627 104.532 21.7215C103.628 20.8161 103.119 19.5885 103.117 18.308C103.117 18.1981 103.161 18.0928 103.239 18.0151C103.316 17.9374 103.421 17.8938 103.531 17.8938C103.641 17.8938 103.746 17.9374 103.824 18.0151C103.901 18.0928 103.945 18.1981 103.945 18.308C103.945 19.3693 104.366 20.3872 105.116 21.1377C105.866 21.8881 106.882 22.3098 107.943 22.3098C109.003 22.3098 110.02 21.8881 110.769 21.1377C111.519 20.3872 111.94 19.3693 111.94 18.308C111.94 18.1981 111.984 18.0928 112.062 18.0151C112.139 17.9374 112.244 17.8938 112.354 17.8938C112.464 17.8938 112.569 17.9374 112.647 18.0151C112.724 18.0928 112.768 18.1981 112.768 18.308C112.766 19.5885 112.257 20.8161 111.353 21.7215C110.448 22.627 109.222 23.1364 107.943 23.1381Z" fill="#101010"/>
            <path d="M103.531 18.782C103.477 18.782 103.423 18.7714 103.373 18.7506C103.322 18.7298 103.277 18.6993 103.238 18.6609L101.83 17.2506C101.493 16.9138 101.264 16.4847 101.171 16.0175C101.079 15.5504 101.126 15.0662 101.308 14.6262C101.491 14.1861 101.799 13.8101 102.195 13.5455C102.59 13.2809 103.055 13.1396 103.531 13.1396C104.007 13.1396 104.472 13.2809 104.868 13.5455C105.263 13.8101 105.572 14.1861 105.754 14.6262C105.936 15.0662 105.984 15.5504 105.891 16.0175C105.798 16.4847 105.569 16.9138 105.233 17.2506L103.824 18.6609C103.785 18.6993 103.74 18.7298 103.69 18.7506C103.639 18.7714 103.585 18.782 103.531 18.782ZM103.531 13.9674C103.219 13.9672 102.914 14.0597 102.654 14.2333C102.394 14.4068 102.192 14.6536 102.072 14.9423C101.953 15.231 101.921 15.5488 101.982 15.8554C102.043 16.162 102.193 16.4436 102.414 16.6646L103.531 17.7818L104.647 16.6646C104.868 16.4436 105.018 16.1621 105.079 15.8556C105.14 15.5492 105.109 15.2315 104.989 14.9428C104.87 14.6541 104.668 14.4073 104.408 14.2337C104.148 14.0601 103.843 13.9674 103.531 13.9674Z" fill="#101010"/>
            <path d="M38.9143 138.818H38.8988C38.8441 138.815 38.7928 138.791 38.7561 138.75C38.7195 138.709 38.7005 138.656 38.7033 138.601L38.8688 135.532C38.8702 135.505 38.877 135.478 38.8888 135.454C38.9006 135.429 38.917 135.407 38.9373 135.389C38.9576 135.371 38.9813 135.357 39.0069 135.348C39.0326 135.339 39.0598 135.335 39.087 135.336C39.1417 135.339 39.193 135.364 39.2294 135.405C39.2659 135.446 39.2846 135.5 39.2815 135.555L39.116 138.623C39.1131 138.675 39.0908 138.724 39.0534 138.76C39.016 138.796 38.9663 138.817 38.9143 138.818Z" fill="#101010"/>
            <path d="M77.5969 137.3C77.5518 137.3 77.5079 137.285 77.4719 137.258C77.4359 137.231 77.4097 137.193 77.3973 137.15L74.9149 128.378C74.9075 128.352 74.9053 128.324 74.9085 128.297C74.9116 128.27 74.9201 128.244 74.9333 128.22C74.9466 128.197 74.9644 128.176 74.9857 128.159C75.007 128.142 75.0315 128.13 75.0576 128.122C75.0838 128.115 75.1111 128.112 75.1381 128.116C75.1651 128.119 75.1912 128.127 75.2149 128.141C75.2386 128.154 75.2595 128.172 75.2764 128.193C75.2932 128.214 75.3057 128.239 75.3131 128.265L77.7955 137.037C77.8041 137.068 77.8055 137.1 77.7996 137.131C77.7936 137.163 77.7806 137.192 77.7613 137.218C77.7421 137.243 77.7173 137.264 77.6887 137.278C77.6602 137.292 77.6288 137.3 77.5969 137.3Z" fill="#101010"/>
            <path d="M54.503 121.783C55.3888 121.783 56.2659 121.609 57.0843 121.269C57.9027 120.93 58.6463 120.433 59.2727 119.806C59.8991 119.179 60.3959 118.434 60.7348 117.615C61.0737 116.796 61.2481 115.918 61.248 115.031H47.758C47.7578 115.918 47.9322 116.796 48.2711 117.615C48.61 118.434 49.1069 119.179 49.7332 119.806C50.3596 120.433 51.1032 120.93 51.9216 121.269C52.74 121.609 53.6172 121.783 54.503 121.783Z" fill="white"/>
            <path d="M61.248 115.238H47.758C47.7032 115.238 47.6505 115.217 47.6117 115.178C47.5729 115.139 47.5511 115.086 47.5511 115.031C47.5511 114.976 47.5729 114.924 47.6117 114.885C47.6505 114.846 47.7032 114.824 47.758 114.824H61.248C61.3028 114.824 61.3555 114.846 61.3943 114.885C61.4331 114.924 61.4549 114.976 61.4549 115.031C61.4549 115.086 61.4331 115.139 61.3943 115.178C61.3555 115.217 61.3028 115.238 61.248 115.238Z" fill="#101010"/>
            <path d="M44.0302 113.984H43.9599C43.4496 113.972 42.9519 113.822 42.5195 113.551C42.087 113.279 41.7357 112.896 41.5027 112.441C41.2697 111.987 41.1636 111.478 41.1955 110.968C41.2275 110.458 41.3964 109.966 41.6843 109.544C41.7156 109.499 41.7632 109.469 41.8167 109.459C41.8703 109.449 41.9255 109.461 41.9704 109.492C42.0153 109.523 42.0463 109.57 42.0566 109.624C42.067 109.677 42.0558 109.733 42.0256 109.778C41.7215 110.223 41.5743 110.757 41.6073 111.296C41.6404 111.834 41.8518 112.346 42.2081 112.751C42.5644 113.156 43.0453 113.43 43.5748 113.53C44.1043 113.631 44.6521 113.552 45.1317 113.306C45.6113 113.06 45.9952 112.661 46.2229 112.172C46.4505 111.683 46.5089 111.132 46.3887 110.606C46.2685 110.08 45.9766 109.609 45.5593 109.268C45.1419 108.927 44.6228 108.735 44.084 108.722C44.0291 108.722 43.9765 108.7 43.9377 108.661C43.8989 108.622 43.8771 108.57 43.8771 108.515C43.8771 108.46 43.8989 108.407 43.9377 108.368C43.9765 108.329 44.0291 108.308 44.084 108.308C44.8359 108.316 45.5536 108.624 46.0791 109.162C46.6045 109.701 46.8948 110.426 46.886 111.179C46.8773 111.931 46.5701 112.65 46.0322 113.176C45.4943 113.702 44.7697 113.992 44.0178 113.984H44.0302Z" fill="#E52322"/>
            <path d="M65.6884 112.16H65.6202C65.1102 112.148 64.613 111.998 64.1809 111.727C63.7488 111.455 63.3978 111.072 63.1649 110.618C62.9319 110.163 62.8257 109.655 62.8573 109.145C62.889 108.635 63.0573 108.144 63.3446 107.722C63.3756 107.676 63.4234 107.645 63.4774 107.635C63.5314 107.625 63.5873 107.636 63.6327 107.667C63.6781 107.698 63.7093 107.746 63.7195 107.8C63.7297 107.854 63.718 107.91 63.687 107.956C63.4413 108.316 63.2971 108.736 63.2698 109.171C63.2425 109.606 63.333 110.041 63.5317 110.429C63.7305 110.817 64.0302 111.145 64.3992 111.377C64.7682 111.609 65.1928 111.737 65.6284 111.747H65.6874C66.3296 111.755 66.9485 111.507 67.4081 111.058C67.8676 110.609 68.1301 109.995 68.1378 109.352C68.1455 108.71 67.8977 108.09 67.449 107.63C67.0004 107.17 66.3875 106.907 65.7453 106.9C65.7177 106.9 65.6904 106.894 65.6649 106.883C65.6395 106.873 65.6165 106.857 65.5972 106.837C65.5779 106.817 65.5628 106.794 65.5527 106.768C65.5426 106.742 65.5378 106.715 65.5385 106.687C65.5398 106.633 65.5622 106.582 65.6008 106.544C65.6395 106.506 65.6913 106.485 65.7453 106.485C66.4973 106.494 67.2148 106.802 67.7402 107.34C68.2656 107.879 68.5558 108.604 68.5469 109.357C68.5379 110.11 68.2307 110.828 67.6927 111.354C67.1547 111.88 66.43 112.17 65.6781 112.161L65.6884 112.16Z" fill="#E52322"/>
        </g>
        <defs>
            <clipPath id="clip5">
                <rect width="139" height="266" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}