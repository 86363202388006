import {ScreenWrapper} from "./components/ScreenWrapper";
import React from "react";


function App() {
  return (
    <ScreenWrapper />
  );
}

export default App;
