const SexType = {
    Male: 'male',
    Female: 'female'
}

export const sections = [
    {
        id: '1',
        title: 'А сейчас готовься к полному погружению в работу компании',
        text: {
            [SexType.Male]: 'Ты специалист в своем деле, в компании работаешь больше года. В коллективе тебя уважают! Каждый готов обратиться к тебе за советом, потому что знает, что ты сможешь поддержать и ответить на любой вопрос :)',
            [SexType.Female]: 'Ты специалист в своем деле, в компании работаешь больше года. В коллективе тебя уважают! Каждый готов обратиться к тебе за советом, потому что знает, что ты сможешь поддержать и ответить на любой вопрос :)'
        },
    },
    {
        id: '2',
        title: 'Теперь к делу',
        text: {
            [SexType.Male]: 'На общем собрании в офисе объявили, что сейчас начинается отбор новых наставников для стажеров и скоро начнется обучение, без которого наставником не стать. Как в том самом меме — все оборачиваются на тебя. \n' +
            'Под все необходимые критерии ты подходишь: умен, красив и обаятелен.\nНу, а дальше — выбор за тобой. ',
            [SexType.Female]: 'На общем собрании в офисе объявили, что сейчас начинается отбор новых наставников для стажеров и скоро начнется обучение, без которого наставником не стать. Как в том самом меме — все оборачиваются на тебя. \n' +
            'Под все необходимые критерии ты подходишь: умна, красива и обаятельна.\nВперед!'
        },
    },
    {
        id: '3',
        title: 'Твое обучение началось',
        text: {
            [SexType.Male]: 'Ты прослушал парочку лекций, ' +
            'на которых тебе объяснили, ' +
            'как общаться со стажером, ' +
            'организовывать адаптацию и направлять все его усилия в правильное русло. ' +
            '\n\n' +
            'Во время обучения ты узнал про ' +
            '«шаги наставничества». Попробуй ' +
            'на интуитивном уровне ответить на вопросы.',
            [SexType.Female]: 'Ты прослушала парочку лекций, ' +
            'на которых тебе объяснили, ' +
            'как общаться со стажером, организовывать адаптацию и направлять все его усилия в правильное русло.' +
            '\n\n' +
            'Во время обучения ты узнала про \n' +
            '«шаги наставничества». Попробуй \n' +
            'на интуитивном уровне ответить на вопросы.'
        },
    },
    {
        id: '4',
        title: 'Этот момент настал… ',
        text: {
            [SexType.Male]: 'Волнительно, правда? Хоть я всего лишь тест, я сам что-то переживаю.\n' +
            'Но ты мне нравишься, поэтому в путь!\n' +
            '\n' +
            'Тебе подобрали стажера, его выход уже на следующей неделе. Ты волнуешься, но обучение было явно не зря!',
            [SexType.Female]: 'Волнительно, правда? Хоть я всего лишь тест, я сам что-то переживаю.\n' +
            'Но ты мне нравишься, поэтому в путь!\n' +
            '\n' +
            'Тебе подобрали стажера, его выход уже на следующей неделе. Ты волнуешься, но обучение было явно не зря!'
        },
    },
]
