import React from "react";

export const IntroImg = (props) => {
    return <svg {...props} viewBox="0 0 235 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M197.676 148.602C193.256 159.886 188.921 171.595 180.782 180.456C167.735 194.672 147.356 198.965 128.274 199.454C105.791 200.049 83.0325 196.33 62.5262 186.852C41.6375 177.205 22.6188 160.375 16.1375 137.892C13.29 128.032 12.9713 117.62 12.9713 107.356C12.9925 91.355 13.9913 74.5675 22.215 60.9462C26.8475 53.275 33.4775 47.1337 39.6825 40.7375C47.4388 32.7262 54.7275 24.0987 63.7162 17.5537C74.7875 9.49997 87.9837 4.97372 101.329 2.42372C136.179 -4.22753 174.896 3.16747 200.672 28.0087C211.765 38.6975 219.585 53.7 216.376 69.5737C214.91 76.8412 215.016 83.7262 214.485 91.1C213.805 100.535 211.574 109.779 208.747 118.746C205.539 128.904 201.544 138.742 197.676 148.602Z" fill="#B3B3B3"/>
        <path d="M93.5513 73.9725C79.6963 63.2837 61.8038 58.6937 44.2938 58.8637C35.8788 58.9487 27.1238 60.2025 20.1112 64.8775C13.0987 69.5525 8.36 78.3925 10.5487 86.5312C11.8025 91.185 15.0538 95.01 18.39 98.495C39.5338 120.51 71.0475 137.765 102.136 137.638C106.79 137.616 117.904 138.254 120.688 133.473C123.429 128.776 118.86 116.876 117.458 112.053C113.25 97.475 105.749 83.3862 93.5513 73.9725Z" fill="#00B338"/>
        <path d="M115.056 133.77C114.483 133.77 113.909 133.515 113.526 133.026C113.059 132.431 66.9675 73.7175 18.1138 80.305C17.0513 80.4537 16.0738 79.71 15.925 78.6475C15.7763 77.585 16.52 76.6075 17.5825 76.4587C38.79 73.6112 62.6538 82.1112 86.6025 101.045C104.559 115.24 116.459 130.519 116.586 130.668C117.245 131.518 117.096 132.729 116.246 133.388C115.885 133.643 115.46 133.77 115.056 133.77Z" fill="#00932E"/>
        <path d="M59.4025 113.667C49.8825 112.647 40.235 114.602 31.2038 117.832C25.275 119.957 19.495 122.677 14.5225 126.545C10.4425 129.732 7.02125 133.642 3.79125 137.659C2.30375 139.508 -0.224996 141.25 1.58125 143.354C2.98375 144.969 5.2575 145.925 7.19125 146.711C18.9638 151.535 31.8838 153.533 44.5488 152.491C49.2663 152.109 53.9838 151.28 58.34 149.495C64.545 146.945 69.7088 142.546 74.1925 137.638C76.4875 135.13 78.6125 132.495 80.6738 129.818C82.8625 126.991 84.18 125.822 81.2262 123.102C78.4212 120.531 75.17 118.47 71.7063 116.94C67.8175 115.197 63.6313 114.114 59.4025 113.667Z" fill="#00932E"/>
        <path d="M6.17126 143.481C5.34251 143.481 4.55626 142.929 4.30126 142.1C3.98251 141.08 4.57751 139.996 5.59751 139.677C6.02251 139.55 47.9063 126.949 78.825 123.081C79.8875 122.954 80.865 123.697 80.9925 124.76C81.12 125.822 80.3763 126.8 79.3138 126.927C48.735 130.752 7.14876 143.269 6.72376 143.375C6.55376 143.46 6.36251 143.481 6.17126 143.481Z" fill="#01753F"/>
        <path d="M176.171 125.334C185.691 124.314 195.339 126.269 204.37 129.499C210.299 131.624 216.079 134.344 221.051 138.211C225.131 141.399 228.552 145.309 231.782 149.325C233.27 151.174 235.799 152.916 233.992 155.02C232.59 156.635 230.316 157.591 228.382 158.377C216.61 163.201 203.69 165.199 191.025 164.157C186.307 163.775 181.59 162.946 177.234 161.161C171.029 158.611 165.865 154.212 161.381 149.304C159.086 146.796 156.961 144.161 154.9 141.484C152.711 138.657 151.394 137.489 154.347 134.769C157.152 132.197 160.404 130.136 163.867 128.606C167.756 126.864 171.942 125.78 176.171 125.334Z" fill="#00932E"/>
        <path d="M230.508 155.19C205.411 155.19 157.386 140.166 155.282 139.507C154.262 139.189 153.689 138.084 154.007 137.064C154.326 136.044 155.431 135.47 156.451 135.789C156.982 135.959 208.896 152.194 232.59 151.259C233.674 151.216 234.566 152.045 234.609 153.129C234.651 154.191 233.823 155.105 232.739 155.147C232.016 155.19 231.273 155.19 230.508 155.19Z" fill="#01753F"/>
        <path d="M139.77 54.3587C148.716 42.2462 171.029 28.2212 187.009 23.7587C191.492 39.5687 195.997 56.3137 191.811 72.2087C188.581 84.4062 180.442 94.84 170.71 102.851C160.977 110.862 149.672 116.685 138.346 122.252C130.292 126.226 127.721 96.795 127.572 92.3325C127.126 78.8175 131.759 65.1962 139.77 54.3587Z" fill="#00932E"/>
        <path d="M138.367 121.126C138.155 121.126 137.942 121.084 137.73 121.02C136.71 120.659 136.179 119.554 136.519 118.555C136.71 118.024 155.41 64.3675 183.46 26.6487C184.097 25.7775 185.33 25.6075 186.18 26.245C187.051 26.8825 187.221 28.0937 186.584 28.965C158.895 66.195 140.386 119.277 140.195 119.809C139.94 120.637 139.175 121.126 138.367 121.126Z" fill="#01753F"/>
        <path d="M137.029 161.735C137.029 161.735 133.331 84.0237 210.086 96.1362C286.841 108.249 137.029 161.735 137.029 161.735Z" fill="#00B338"/>
        <path d="M138.58 159.525C138.452 159.483 138.325 159.419 138.197 159.355C137.262 158.824 136.944 157.634 137.475 156.699C137.836 156.083 146.251 141.59 161.849 127.841C182.61 109.524 206.134 101.534 229.891 104.764C230.954 104.891 231.697 105.89 231.549 106.952C231.4 108.015 230.422 108.759 229.36 108.61C206.474 105.507 184.65 112.924 164.505 130.667C149.354 144.013 140.917 158.484 140.832 158.633C140.386 159.44 139.43 159.801 138.58 159.525Z" fill="#00932E"/>
        <path d="M167.841 95.9875H153.625V135.831H167.841V95.9875Z" fill="white"/>
        <path d="M97.5675 131.454H69.3688V171.298H97.5675V131.454Z" fill="white"/>
        <path d="M147.123 171.68H87.835C81.3963 171.68 76.1688 166.452 76.1688 160.014V31.7062C76.1688 25.2675 81.3963 20.04 87.835 20.04H147.123C153.561 20.04 158.789 25.2675 158.789 31.7062V160.014C158.789 166.452 153.561 171.68 147.123 171.68Z" fill="white"/>
        <path d="M82.3525 21.4C78.6763 23.355 76.1688 27.2437 76.1688 31.7062V160.014C76.1688 166.452 81.3963 171.68 87.835 171.68H147.123C153.349 171.68 158.427 166.792 158.746 160.63C105.047 154.17 86.9638 55.06 82.3525 21.4Z" fill="#F0F0F0"/>
        <path d="M147.123 20.04H134.479H100.458H87.8138C81.375 20.04 76.1475 25.2675 76.1475 31.7062V160.014C76.1475 166.452 81.375 171.68 87.8138 171.68H147.101C153.54 171.68 158.767 166.452 158.767 160.014V31.7062C158.789 25.2675 153.561 20.04 147.123 20.04ZM155.877 160.014C155.877 164.837 151.946 168.769 147.123 168.769H87.835C83.0113 168.769 79.08 164.837 79.08 160.014V31.7062C79.08 26.8825 83.0113 22.9512 87.835 22.9512H101.201L101.308 23.3975C101.966 25.99 104.283 27.8175 106.96 27.8175H127.976C130.654 27.8175 132.991 25.99 133.629 23.3975L133.735 22.9512H147.101C151.925 22.9512 155.856 26.8825 155.856 31.7062V160.014H155.877Z" fill="#3D4045"/>
        <path d="M126.234 24.9063H109.701C108.894 24.9063 108.235 24.2475 108.235 23.44C108.235 22.6325 108.894 21.9738 109.701 21.9738H126.234C127.041 21.9738 127.7 22.6325 127.7 23.44C127.679 24.2475 127.02 24.9063 126.234 24.9063Z" fill="#323439"/>
        <path d="M150.034 165.858H84.9025C82.7563 165.858 81.0137 164.115 81.0137 161.969V112.393H153.923V161.969C153.923 164.115 152.18 165.858 150.034 165.858Z" fill="#B3B3B3"/>
        <path d="M153.923 104.615H81.0137V112.393H153.923V104.615Z" fill="#B3B3B3"/>
        <path d="M135.456 161.969H98.5237C97.9925 161.969 97.5462 161.544 97.5462 160.991V153.214C97.5462 152.683 97.9712 152.236 98.5237 152.236H135.456C135.987 152.236 136.434 152.661 136.434 153.214V160.991C136.434 161.523 135.987 161.969 135.456 161.969Z" fill="#F8F8F8"/>
        <path d="M150.034 161.969H141.3C140.769 161.969 140.323 161.544 140.323 160.991V153.214C140.323 152.683 140.748 152.236 141.3 152.236H150.055C150.586 152.236 151.033 152.661 151.033 153.214V160.991C151.011 161.523 150.565 161.969 150.034 161.969Z" fill="#F8F8F8"/>
        <path d="M92.68 161.969H83.925C83.3937 161.969 82.9475 161.544 82.9475 160.991V153.214C82.9475 152.683 83.3725 152.236 83.925 152.236H92.68C93.2112 152.236 93.6575 152.661 93.6575 153.214V160.991C93.6575 161.523 93.2325 161.969 92.68 161.969Z" fill="#F8F8F8"/>
        <path d="M150.034 149.325H143.234C142.703 149.325 142.256 148.9 142.256 148.348V140.57C142.256 140.039 142.681 139.593 143.234 139.593H150.034C150.565 139.593 151.011 140.018 151.011 140.57V148.348C151.011 148.879 150.565 149.325 150.034 149.325Z" fill="#F8F8F8"/>
        <path d="M90.7462 149.325H83.9462C83.415 149.325 82.9687 148.9 82.9687 148.348V140.57C82.9687 140.039 83.3937 139.593 83.9462 139.593H90.7462C91.2775 139.593 91.7237 140.018 91.7237 140.57V148.348C91.7237 148.879 91.2775 149.325 90.7462 149.325Z" fill="#F8F8F8"/>
        <path d="M102.413 149.325H97.5463C97.015 149.325 96.5688 148.9 96.5688 148.348V140.57C96.5688 140.039 96.9938 139.593 97.5463 139.593H102.413C102.944 139.593 103.39 140.018 103.39 140.57V148.348C103.39 148.879 102.944 149.325 102.413 149.325Z" fill="#F8F8F8"/>
        <path d="M111.146 149.325H106.28C105.749 149.325 105.302 148.9 105.302 148.348V140.57C105.302 140.039 105.727 139.593 106.28 139.593H111.146C111.677 139.593 112.124 140.018 112.124 140.57V148.348C112.124 148.879 111.699 149.325 111.146 149.325Z" fill="#F8F8F8"/>
        <path d="M119.901 149.325H115.035C114.504 149.325 114.058 148.9 114.058 148.348V140.57C114.058 140.039 114.483 139.593 115.035 139.593H119.901C120.433 139.593 120.879 140.018 120.879 140.57V148.348C120.879 148.879 120.433 149.325 119.901 149.325Z" fill="#F8F8F8"/>
        <path d="M128.656 149.325H123.79C123.259 149.325 122.812 148.9 122.812 148.348V140.57C122.812 140.039 123.237 139.593 123.79 139.593H128.656C129.187 139.593 129.634 140.018 129.634 140.57V148.348C129.634 148.879 129.187 149.325 128.656 149.325Z" fill="#F8F8F8"/>
        <path d="M137.411 149.325H132.545C132.014 149.325 131.567 148.9 131.567 148.348V140.57C131.567 140.039 131.992 139.593 132.545 139.593H137.411C137.943 139.593 138.389 140.018 138.389 140.57V148.348C138.368 148.879 137.943 149.325 137.411 149.325Z" fill="#F8F8F8"/>
        <path d="M102.413 136.681H97.5463C97.015 136.681 96.5688 136.256 96.5688 135.704V127.926C96.5688 127.395 96.9938 126.949 97.5463 126.949H102.413C102.944 126.949 103.39 127.374 103.39 127.926V135.704C103.39 136.256 102.944 136.681 102.413 136.681Z" fill="#F8F8F8"/>
        <path d="M93.6575 136.681H88.7912C88.26 136.681 87.8137 136.256 87.8137 135.704V127.926C87.8137 127.395 88.2387 126.949 88.7912 126.949H93.6575C94.1888 126.949 94.635 127.374 94.635 127.926V135.704C94.635 136.256 94.1888 136.681 93.6575 136.681Z" fill="#F8F8F8"/>
        <path d="M111.146 136.681H106.28C105.749 136.681 105.302 136.256 105.302 135.704V127.926C105.302 127.395 105.727 126.949 106.28 126.949H111.146C111.677 126.949 112.124 127.374 112.124 127.926V135.704C112.124 136.256 111.699 136.681 111.146 136.681Z" fill="#F8F8F8"/>
        <path d="M119.901 136.681H115.035C114.504 136.681 114.058 136.256 114.058 135.704V127.926C114.058 127.395 114.483 126.949 115.035 126.949H119.901C120.433 126.949 120.879 127.374 120.879 127.926V135.704C120.879 136.256 120.433 136.681 119.901 136.681Z" fill="#F8F8F8"/>
        <path d="M128.656 136.681H123.79C123.259 136.681 122.812 136.256 122.812 135.704V127.926C122.812 127.395 123.237 126.949 123.79 126.949H128.656C129.187 126.949 129.634 127.374 129.634 127.926V135.704C129.634 136.256 129.187 136.681 128.656 136.681Z" fill="#F8F8F8"/>
        <path d="M137.411 136.681H132.545C132.014 136.681 131.567 136.256 131.567 135.704V127.926C131.567 127.395 131.992 126.949 132.545 126.949H137.411C137.943 126.949 138.389 127.374 138.389 127.926V135.704C138.368 136.256 137.943 136.681 137.411 136.681Z" fill="#F8F8F8"/>
        <path d="M147.123 136.681H142.256C141.725 136.681 141.279 136.256 141.279 135.704V127.926C141.279 127.395 141.704 126.949 142.256 126.949H147.123C147.654 126.949 148.1 127.374 148.1 127.926V135.704C148.1 136.256 147.654 136.681 147.123 136.681Z" fill="#F8F8F8"/>
        <path d="M98.5238 125.015H93.6575C93.1262 125.015 92.68 124.59 92.68 124.038V116.26C92.68 115.729 93.105 115.283 93.6575 115.283H98.5238C99.055 115.283 99.5012 115.708 99.5012 116.26V124.038C99.5012 124.59 99.055 125.015 98.5238 125.015Z" fill="#F8F8F8"/>
        <path d="M89.7687 125.015H84.9025C84.3713 125.015 83.925 124.59 83.925 124.038V116.26C83.925 115.729 84.35 115.283 84.9025 115.283H89.7687C90.3 115.283 90.7463 115.708 90.7463 116.26V124.038C90.7463 124.59 90.3 125.015 89.7687 125.015Z" fill="#F8F8F8"/>
        <path d="M107.279 125.015H102.413C101.881 125.015 101.435 124.59 101.435 124.038V116.26C101.435 115.729 101.86 115.283 102.413 115.283H107.279C107.81 115.283 108.256 115.708 108.256 116.26V124.038C108.235 124.59 107.81 125.015 107.279 125.015Z" fill="#F8F8F8"/>
        <path d="M116.012 125.015H111.146C110.615 125.015 110.169 124.59 110.169 124.038V116.26C110.169 115.729 110.594 115.283 111.146 115.283H116.012C116.544 115.283 116.99 115.708 116.99 116.26V124.038C116.99 124.59 116.544 125.015 116.012 125.015Z" fill="#F8F8F8"/>
        <path d="M124.767 125.015H119.901C119.37 125.015 118.924 124.59 118.924 124.038V116.26C118.924 115.729 119.349 115.283 119.901 115.283H124.767C125.299 115.283 125.745 115.708 125.745 116.26V124.038C125.745 124.59 125.299 125.015 124.767 125.015Z" fill="#F8F8F8"/>
        <path d="M133.522 125.015H128.656C128.125 125.015 127.679 124.59 127.679 124.038V116.26C127.679 115.729 128.104 115.283 128.656 115.283H133.522C134.054 115.283 134.5 115.708 134.5 116.26V124.038C134.479 124.59 134.054 125.015 133.522 125.015Z" fill="#F8F8F8"/>
        <path d="M142.256 125.015H137.39C136.859 125.015 136.412 124.59 136.412 124.038V116.26C136.412 115.729 136.837 115.283 137.39 115.283H142.256C142.787 115.283 143.234 115.708 143.234 116.26V124.038C143.234 124.59 142.809 125.015 142.256 125.015Z" fill="#F8F8F8"/>
        <path d="M151.011 125.015H146.145C145.614 125.015 145.167 124.59 145.167 124.038V116.26C145.167 115.729 145.592 115.283 146.145 115.283H151.011C151.542 115.283 151.989 115.708 151.989 116.26V124.038C151.989 124.59 151.542 125.015 151.011 125.015Z" fill="#F8F8F8"/>
        <path d="M150.523 111.415H84.435C83.3512 111.415 82.5012 110.544 82.5012 109.481V107.548C82.5012 106.464 83.3725 105.614 84.435 105.614H150.544C151.628 105.614 152.478 106.485 152.478 107.548V109.481C152.478 110.544 151.606 111.415 150.523 111.415Z" fill="#F8F8F8"/>
        <path d="M92.68 94.8825C92.68 98.1125 90.0663 100.705 86.8575 100.705C83.6487 100.705 81.035 98.0912 81.035 94.8825C81.035 91.6525 83.6487 89.06 86.8575 89.06C90.0663 89.06 92.68 91.6737 92.68 94.8825Z" fill="#FF1E1C"/>
        <path d="M92.68 49.195C92.68 52.425 90.0663 55.0175 86.8575 55.0175C83.6487 55.0175 81.035 52.4038 81.035 49.195C81.035 45.965 83.6487 43.3725 86.8575 43.3725C90.0663 43.3725 92.68 45.9862 92.68 49.195Z" fill="#B3B3B3"/>
        <path d="M144.211 100.726H98.5238C97.44 100.726 96.59 99.855 96.59 98.7925V84.2149C96.59 83.1312 97.4613 82.2812 98.5238 82.2812H144.211C145.295 82.2812 146.145 83.1524 146.145 84.2149V98.7925C146.145 99.855 145.274 100.726 144.211 100.726Z" fill="#FF1E1C"/>
        <path d="M144.211 57.95H98.5238C97.44 57.95 96.59 57.0788 96.59 56.0163V41.4388C96.59 40.355 97.4613 39.505 98.5238 39.505H144.211C145.295 39.505 146.145 40.3763 146.145 41.4388V56.0163C146.145 57.0788 145.274 57.95 144.211 57.95Z" fill="#D6D6D6"/>
        <path d="M144.211 38.5063H98.5238C97.44 38.5063 96.59 37.635 96.59 36.5725V32.6838C96.59 31.6 97.4613 30.75 98.5238 30.75H144.211C145.295 30.75 146.145 31.6213 146.145 32.6838V36.5725C146.145 37.635 145.274 38.5063 144.211 38.5063Z" fill="#D6D6D6"/>
        <path d="M152.945 79.3275H107.257C106.174 79.3275 105.324 78.4563 105.324 77.3938V62.8163C105.324 61.7325 106.195 60.8825 107.257 60.8825H152.945C154.029 60.8825 154.879 61.7538 154.879 62.8163V77.3938C154.9 78.4563 154.029 79.3275 152.945 79.3275Z" fill="#00932E"/>
        <path d="M148.589 110.437C148.313 110.437 148.1 110.225 148.1 109.949V107.037C148.1 106.761 148.313 106.549 148.589 106.549C148.865 106.549 149.078 106.761 149.078 107.037V109.949C149.056 110.225 148.844 110.437 148.589 110.437Z" fill="#A4ADB4"/>
        <path d="M222.709 48.2175H185.712C182.865 48.2175 180.549 50.5338 180.549 53.3813V77.4788C180.549 80.3263 182.865 82.6425 185.712 82.6425H189.304C189.304 82.6425 187.434 89.5275 184.862 91.2488C184.862 91.2488 194.319 87.8063 197.995 82.6425H222.73C225.577 82.6425 227.894 80.3263 227.894 77.4788V53.3813C227.872 50.5338 225.556 48.2175 222.709 48.2175Z" fill="#FF1E1C"/>
        <path d="M196.763 65.4725C196.763 67.555 195.063 69.255 192.98 69.255C190.898 69.255 189.198 67.555 189.198 65.4725C189.198 63.39 190.898 61.69 192.98 61.69C195.063 61.69 196.763 63.39 196.763 65.4725Z" fill="white"/>
        <path d="M208.131 65.4725C208.131 67.555 206.431 69.255 204.349 69.255C202.266 69.255 200.566 67.555 200.566 65.4725C200.566 63.39 202.266 61.69 204.349 61.69C206.431 61.69 208.131 63.39 208.131 65.4725Z" fill="white"/>
        <path d="M219.5 65.4725C219.5 67.555 217.8 69.255 215.718 69.255C213.635 69.255 211.935 67.555 211.935 65.4725C211.935 63.39 213.635 61.69 215.718 61.69C217.8 61.69 219.5 63.39 219.5 65.4725Z" fill="white"/>
        <path d="M23.6812 7.39624H65.48C68.71 7.39624 71.3025 10.01 71.3025 13.2187V40.44C71.3025 43.67 68.6887 46.2625 65.48 46.2625H61.4212C61.4212 46.2625 63.525 54.04 66.4362 55.9737C66.4362 55.9737 55.7475 52.085 51.6037 46.2625H23.6812C20.4513 46.2625 17.8588 43.6487 17.8588 40.44V13.24C17.8375 10.01 20.4513 7.39624 23.6812 7.39624Z" fill="#FF1E1C"/>
        <path d="M64.0137 38.5063H25.1262C23.7875 38.5063 22.7037 37.4225 22.7037 36.0838C22.7037 34.745 23.7875 33.6613 25.1262 33.6613H64.0137C65.3525 33.6613 66.4362 34.745 66.4362 36.0838C66.4362 37.4225 65.3525 38.5063 64.0137 38.5063Z" fill="white"/>
        <path d="M64.0137 28.795H25.1262C23.7875 28.795 22.7037 27.7113 22.7037 26.3725C22.7037 25.0338 23.7875 23.95 25.1262 23.95H64.0137C65.3525 23.95 66.4362 25.0338 66.4362 26.3725C66.4362 27.69 65.3525 28.795 64.0137 28.795Z" fill="white"/>
        <path d="M64.0137 19.0625H25.1262C23.7875 19.0625 22.7037 17.9787 22.7037 16.64C22.7037 15.3012 23.7875 14.2175 25.1262 14.2175H64.0137C65.3525 14.2175 66.4362 15.3012 66.4362 16.64C66.4362 17.9787 65.3525 19.0625 64.0137 19.0625Z" fill="white"/>
        <path d="M142.256 44.35H100.457C99.9262 44.35 99.48 43.925 99.48 43.3725C99.48 42.8413 99.905 42.395 100.457 42.395H142.256C142.787 42.395 143.234 42.82 143.234 43.3725C143.234 43.9038 142.809 44.35 142.256 44.35Z" fill="#B3B3B3"/>
        <path d="M142.256 49.195H100.457C99.9262 49.195 99.48 48.77 99.48 48.2175C99.48 47.6862 99.905 47.24 100.457 47.24H142.256C142.787 47.24 143.234 47.665 143.234 48.2175C143.234 48.77 142.809 49.195 142.256 49.195Z" fill="#B3B3B3"/>
        <path d="M142.256 54.0612H100.457C99.9262 54.0612 99.48 53.6362 99.48 53.0837C99.48 52.5525 99.905 52.1062 100.457 52.1062H142.256C142.787 52.1062 143.234 52.5312 143.234 53.0837C143.234 53.6362 142.809 54.0612 142.256 54.0612Z" fill="#B3B3B3"/>
        <path d="M142.256 87.105H100.457C99.9262 87.105 99.48 86.68 99.48 86.1275C99.48 85.5963 99.905 85.15 100.457 85.15H142.256C142.787 85.15 143.234 85.575 143.234 86.1275C143.234 86.68 142.809 87.105 142.256 87.105Z" fill="#BA1614"/>
        <path d="M142.256 91.9712H100.457C99.9262 91.9712 99.48 91.5462 99.48 90.9937C99.48 90.4625 99.905 90.0162 100.457 90.0162H142.256C142.787 90.0162 143.234 90.4412 143.234 90.9937C143.234 91.5462 142.809 91.9712 142.256 91.9712Z" fill="#BA1614"/>
        <path d="M142.256 96.8375H100.457C99.9262 96.8375 99.48 96.4125 99.48 95.86C99.48 95.3288 99.905 94.8825 100.457 94.8825H142.256C142.787 94.8825 143.234 95.3075 143.234 95.86C143.234 96.3913 142.809 96.8375 142.256 96.8375Z" fill="#BA1614"/>
        <path d="M151.011 66.705H109.212C108.681 66.705 108.235 66.28 108.235 65.7275C108.235 65.1963 108.66 64.75 109.212 64.75H151.011C151.542 64.75 151.989 65.175 151.989 65.7275C151.989 66.2588 151.542 66.705 151.011 66.705Z" fill="#01753F"/>
        <path d="M142.256 35.595H100.457C99.9262 35.595 99.48 35.17 99.48 34.6175C99.48 34.0863 99.905 33.64 100.457 33.64H142.256C142.787 33.64 143.234 34.065 143.234 34.6175C143.234 35.1488 142.809 35.595 142.256 35.595Z" fill="#B3B3B3"/>
        <path d="M151.011 71.55H109.212C108.681 71.55 108.235 71.125 108.235 70.5725C108.235 70.0412 108.66 69.595 109.212 69.595H151.011C151.542 69.595 151.989 70.02 151.989 70.5725C151.989 71.125 151.542 71.55 151.011 71.55Z" fill="#01753F"/>
        <path d="M151.011 76.4162H109.212C108.681 76.4162 108.235 75.9912 108.235 75.4387C108.235 74.9075 108.66 74.4612 109.212 74.4612H151.011C151.542 74.4612 151.989 74.8862 151.989 75.4387C151.989 75.9912 151.542 76.4162 151.011 76.4162Z" fill="#01753F"/>
    </svg>


}