import React from "react";

export const Section3Img = (props) => {
    return <svg {...props} viewBox="0 0 286 236" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip4)">
            <path d="M116.41 101.697C115.815 101.417 115.147 101.331 114.501 101.451C113.855 101.572 113.262 101.892 112.807 102.368L113.486 100.915C113.84 100.157 113.88 99.2891 113.597 98.5018C113.313 97.7145 112.73 97.0725 111.975 96.717C111.22 96.3615 110.355 96.3216 109.571 96.6062C108.787 96.8907 108.147 97.4763 107.793 98.2342L107.598 98.6533C107.895 97.891 107.886 97.0425 107.572 96.2869C107.258 95.5312 106.664 94.9277 105.915 94.6036C105.166 94.2794 104.321 94.2602 103.558 94.5498C102.795 94.8394 102.174 95.4152 101.826 96.1557L98.3145 103.66L98.0401 101.49C97.9394 100.697 97.5291 99.9764 96.8993 99.4867C96.2695 98.9969 95.4718 98.7783 94.6815 98.8788C94.29 98.9287 93.9121 99.0555 93.5694 99.252C93.2268 99.4485 92.9261 99.7108 92.6845 100.024C92.4429 100.337 92.2651 100.695 92.1614 101.077C92.0576 101.46 92.0299 101.859 92.0799 102.252L92.7181 107.292C92.7181 107.292 110.59 114.901 112.778 115.583L117.925 105.895C118.1 105.519 118.2 105.113 118.219 104.699C118.237 104.284 118.174 103.87 118.034 103.481C117.893 103.091 117.677 102.733 117.398 102.426C117.12 102.12 116.784 101.872 116.41 101.697Z" fill="white"/>
            <path d="M104.653 105.24C104.596 105.24 104.54 105.227 104.489 105.202C104.444 105.18 104.403 105.15 104.369 105.112C104.336 105.075 104.31 105.031 104.293 104.983C104.276 104.936 104.269 104.885 104.271 104.835C104.274 104.784 104.286 104.735 104.308 104.689L107.447 98.0679C107.491 97.9758 107.569 97.9049 107.665 97.8708C107.76 97.8367 107.866 97.8421 107.958 97.886C108.049 97.9298 108.12 98.0084 108.154 98.1046C108.188 98.2007 108.183 98.3064 108.139 98.3986L104.999 105.019C104.968 105.085 104.919 105.141 104.858 105.18C104.796 105.219 104.725 105.24 104.653 105.24Z" fill="#101010"/>
            <path d="M109.258 108.312C109.188 108.312 109.12 108.293 109.06 108.257C109.001 108.22 108.952 108.168 108.92 108.106C108.887 108.044 108.872 107.974 108.877 107.904C108.881 107.834 108.904 107.767 108.944 107.709L112.82 102.123C112.849 102.082 112.885 102.046 112.928 102.019C112.97 101.992 113.017 101.973 113.067 101.964C113.116 101.955 113.167 101.956 113.216 101.967C113.266 101.978 113.312 101.998 113.354 102.027C113.395 102.056 113.43 102.093 113.457 102.135C113.484 102.178 113.503 102.225 113.512 102.275C113.521 102.325 113.52 102.376 113.509 102.425C113.498 102.474 113.478 102.521 113.449 102.563L109.574 108.148C109.538 108.199 109.491 108.241 109.436 108.269C109.381 108.298 109.32 108.313 109.258 108.312Z" fill="#101010"/>
            <path d="M100.972 107.036C100.918 107.036 100.865 107.025 100.816 107.003C100.766 106.981 100.722 106.948 100.686 106.908L98.026 103.916C97.9911 103.878 97.964 103.834 97.9463 103.786C97.9286 103.738 97.9206 103.687 97.9228 103.636C97.9251 103.585 97.9375 103.535 97.9593 103.488C97.9812 103.442 98.012 103.401 98.0501 103.367C98.0881 103.332 98.1325 103.306 98.1808 103.289C98.2291 103.273 98.2801 103.266 98.3311 103.269C98.382 103.273 98.4317 103.286 98.4773 103.309C98.5229 103.332 98.5635 103.364 98.5966 103.403L101.257 106.396C101.306 106.451 101.338 106.519 101.35 106.592C101.361 106.666 101.351 106.741 101.321 106.808C101.291 106.876 101.242 106.934 101.18 106.974C101.118 107.015 101.046 107.036 100.972 107.036Z" fill="#101010"/>
            <path d="M71.0309 136.647L32.9324 141.275V154.834H73.4614L71.0309 136.647Z" fill="#E52322"/>
            <path d="M63.235 102.91H65.1805C66.3667 102.911 67.5275 103.255 68.5238 103.902C69.52 104.548 70.3094 105.469 70.7973 106.555L81.6735 130.764L92.0058 106.673L113.77 115.709L101.538 152.359C97.3137 164.277 73.4538 168.483 69.1595 152.7C62.7614 129.185 63.235 102.91 63.235 102.91Z" fill="#FF0000"/>
            <path d="M31.1886 102.91H63.235L73.4614 147.164L18.6989 146.965L31.1886 102.91Z" fill="#FF0000"/>
            <path d="M99.6983 222.977H64.0916L60.6564 156.74C60.6567 153.815 61.8144 151.01 63.8749 148.942C65.9353 146.873 68.7297 145.711 71.6436 145.711C76.2302 145.711 81.6709 150.131 82.6321 156.74L99.6983 222.977Z" fill="#101010"/>
            <path d="M111.372 235.744H73.7818V222.977H99.6983L111.372 235.744Z" fill="#101010"/>
            <path d="M63.235 222.977H27.6283L29.1921 156.74C29.1924 153.815 30.3501 151.01 32.4105 148.942C34.471 146.873 37.2654 145.711 40.1793 145.711C44.7659 145.711 50.2066 150.131 51.1678 156.74L63.235 222.977Z" fill="#101010"/>
            <path d="M74.909 235.744H37.3173V222.977H63.235L74.909 235.744Z" fill="#101010"/>
            <path d="M31.1886 102.91C29.2833 102.91 27.419 103.466 25.8226 104.51C24.2261 105.554 22.9664 107.041 22.1966 108.791L18.6989 116.742L2.59522 148.651C-1.77567 155.03 12.514 173.61 31.4707 160.458L53.8996 146.404L49.133 130.182L31.1886 102.91Z" fill="#FF0000"/>
            <path d="M38.3359 95.1471C40.3056 93.8922 40.7445 91.0434 39.3162 88.784C37.8878 86.5247 35.1331 85.7104 33.1634 86.9652C31.1937 88.2201 30.7548 91.0689 32.1831 93.3282C33.6115 95.5876 36.3661 96.4019 38.3359 95.1471Z" fill="white"/>
            <path d="M59.7881 90.8001C62.1202 90.738 63.9534 88.5191 63.8827 85.8441C63.812 83.1691 61.8641 81.051 59.5321 81.1131C57.2 81.1752 55.3668 83.3941 55.4375 86.0691C55.5082 88.7441 57.456 90.8622 59.7881 90.8001Z" fill="white"/>
            <path d="M47.6522 100.785C53.8824 100.785 58.9331 95.7152 58.9331 89.461C58.9331 83.2068 53.8824 78.1367 47.6522 78.1367C41.422 78.1367 36.3714 83.2068 36.3714 89.461C36.3714 95.7152 41.422 100.785 47.6522 100.785Z" fill="white"/>
            <path d="M42.4362 94.0776C43.7363 94.0776 44.7902 93.0196 44.7902 91.7146C44.7902 90.4095 43.7363 89.3516 42.4362 89.3516C41.1362 89.3516 40.0823 90.4095 40.0823 91.7146C40.0823 93.0196 41.1362 94.0776 42.4362 94.0776Z" fill="#E52322"/>
            <path d="M55.2145 92.5688C56.5145 92.5688 57.5684 91.5108 57.5684 90.2058C57.5684 88.9007 56.5145 87.8428 55.2145 87.8428C53.9144 87.8428 52.8605 88.9007 52.8605 90.2058C52.8605 91.5108 53.9144 92.5688 55.2145 92.5688Z" fill="#E52322"/>
            <path d="M49.4751 90.0259C49.4198 90.0259 49.366 90.0079 49.3218 89.9745C49.2775 89.9412 49.2453 89.8943 49.2299 89.8409C49.2145 89.7876 49.2167 89.7307 49.2363 89.6788C49.2559 89.6268 49.2918 89.5827 49.3385 89.553L50.85 88.5906C50.2117 88.2587 48.9951 87.5898 48.3428 87.0375C48.3172 87.0158 48.296 86.9892 48.2806 86.9593C48.2652 86.9294 48.2558 86.8967 48.2529 86.8632C48.2501 86.8296 48.2539 86.7958 48.264 86.7637C48.2742 86.7316 48.2906 86.7019 48.3122 86.6761C48.3338 86.6504 48.3603 86.6291 48.3901 86.6137C48.4199 86.5982 48.4524 86.5887 48.4858 86.5859C48.5193 86.583 48.5529 86.5868 48.5849 86.597C48.6169 86.6072 48.6465 86.6236 48.6722 86.6454C49.5121 87.3553 51.4576 88.3279 51.4806 88.3382C51.5211 88.3583 51.5555 88.3888 51.5804 88.4266C51.6053 88.4644 51.6197 88.5082 51.6223 88.5535C51.6248 88.599 51.615 88.6444 51.5942 88.685C51.5733 88.7255 51.542 88.7598 51.5036 88.7841L49.6156 89.9861C49.5736 90.0128 49.5248 90.0266 49.4751 90.0259Z" fill="#101010"/>
            <path d="M46.2225 87.58C46.1929 87.7134 46.1246 87.8351 46.0261 87.9295C45.9277 88.0239 45.8035 88.0868 45.6694 88.1104C45.5353 88.1339 45.3973 88.117 45.2728 88.0618C45.1482 88.0065 45.0428 87.9155 44.9699 87.8001C44.897 87.6847 44.8598 87.5501 44.863 87.4135C44.8663 87.2769 44.9098 87.1443 44.9881 87.0325C45.0665 86.9207 45.1761 86.8348 45.3031 86.7856C45.4301 86.7364 45.5688 86.7261 45.7017 86.7561C45.8795 86.7961 46.0342 86.9054 46.1318 87.0599C46.2295 87.2144 46.2621 87.4015 46.2225 87.58Z" fill="#101010"/>
            <path d="M53.6839 86.331C53.6543 86.4644 53.586 86.586 53.4875 86.6804C53.3891 86.7748 53.265 86.8378 53.1309 86.8613C52.9968 86.8849 52.8587 86.868 52.7342 86.8127C52.6096 86.7575 52.5042 86.6664 52.4313 86.551C52.3584 86.4356 52.3212 86.3011 52.3244 86.1644C52.3277 86.0278 52.3712 85.8952 52.4496 85.7835C52.5279 85.6717 52.6375 85.5858 52.7645 85.5366C52.8915 85.4874 53.0302 85.4771 53.1631 85.507C53.3409 85.5471 53.4956 85.6564 53.5933 85.8109C53.6909 85.9654 53.7235 86.1524 53.6839 86.331Z" fill="#101010"/>
            <path d="M58.439 87.9666C58.3941 87.9671 58.3499 87.9551 58.3114 87.932C58.2823 87.915 58.2569 87.8925 58.2366 87.8656C58.2162 87.8388 58.2014 87.8082 58.1928 87.7756C58.1843 87.7429 58.1822 87.7089 58.1868 87.6755C58.1914 87.6421 58.2025 87.6099 58.2195 87.5808L60.6526 83.4033C60.6678 83.3714 60.6894 83.343 60.7161 83.32C60.7428 83.2969 60.7739 83.2796 60.8075 83.2692C60.8412 83.2588 60.8766 83.2555 60.9116 83.2595C60.9466 83.2634 60.9804 83.2747 61.0108 83.2924C61.0413 83.3101 61.0677 83.334 61.0886 83.3625C61.1094 83.3909 61.1242 83.4235 61.1319 83.4579C61.1396 83.4924 61.1401 83.5281 61.1334 83.5628C61.1267 83.5975 61.113 83.6305 61.093 83.6596L58.6599 87.8371C58.6377 87.8763 58.6055 87.9089 58.5668 87.9316C58.528 87.9543 58.4839 87.9664 58.439 87.9666Z" fill="#101010"/>
            <path d="M37.4858 92.3484C37.425 92.3484 37.3661 92.3265 37.3199 92.2868L33.6498 89.1485C33.6089 89.1021 33.5862 89.0423 33.5859 88.9804C33.5856 88.9184 33.6076 88.8584 33.6479 88.8115C33.6883 88.7646 33.7442 88.7339 33.8053 88.7252C33.8665 88.7165 33.9287 88.7303 33.9804 88.7641L37.6518 91.9037C37.7028 91.9478 37.7345 92.0105 37.7397 92.0779C37.745 92.1454 37.7235 92.2122 37.6798 92.2638C37.6554 92.291 37.6254 92.3126 37.592 92.3272C37.5585 92.3418 37.5223 92.349 37.4858 92.3484Z" fill="#101010"/>
            <path d="M49.5504 72.0331C42.8588 70.1737 36.2131 73.9694 34.8829 78.9927C34.0825 82.0144 34.8267 85.8549 36.7186 86.6648C36.7186 86.6648 39.7823 86.1522 40.6133 81.2186C42.6405 83.8405 50.6738 83.3305 51.9746 79.003C53.7694 81.4262 55.0447 82.3348 56.611 82.6526C56.62 82.6526 57.8391 74.3346 49.5504 72.0331Z" fill="#101010"/>
            <path d="M50.0273 94.8063C50.617 94.6017 50.8723 93.7889 50.5975 92.9909C50.3227 92.1929 49.6219 91.7119 49.0322 91.9166C48.4425 92.1212 48.1872 92.934 48.462 93.732C48.7368 94.53 49.4377 95.011 50.0273 94.8063Z" fill="#101010"/>
            <path d="M39.6329 71.4205L33.7915 58.6059C32.2332 55.1881 31.4298 51.4723 31.4362 47.7135L31.4567 37.7784C31.4674 32.1344 29.6522 26.6395 26.2841 22.1202L23.6965 18.6475L1.52588e-05 33.128L6.28955 37.0146C11.4784 40.2217 15.3778 45.1591 17.3049 50.962C19.3222 57.0375 23.498 62.1542 29.0351 65.3349L39.6329 71.4205Z" fill="#101010"/>
            <path d="M74.909 235.999C74.8735 236 74.8383 235.992 74.8058 235.978C74.7733 235.963 74.7441 235.942 74.7201 235.916L73.593 234.685C73.5471 234.634 73.5233 234.566 73.5267 234.498C73.5302 234.429 73.5607 234.365 73.6115 234.319C73.6622 234.273 73.7292 234.249 73.7975 234.252C73.8659 234.256 73.93 234.286 73.9759 234.337L75.1031 235.57C75.1365 235.607 75.1585 235.652 75.1666 235.701C75.1746 235.75 75.1683 235.801 75.1485 235.846C75.1286 235.892 75.096 235.93 75.0546 235.958C75.0132 235.985 74.9649 235.999 74.9154 235.999H74.909Z" fill="#101010"/>
            <path d="M34.9608 73.6979C34.2421 73.6979 33.8221 73.4801 33.7098 73.0457C33.623 72.7163 33.7583 72.2973 34.057 71.827C33.0217 71.8039 32.3975 71.5707 32.1511 71.1055C31.943 70.7134 32.0388 70.2226 32.4371 69.6434C33.7608 67.7212 38.7138 64.7611 41.4533 65.1173C41.521 65.1261 41.5825 65.1616 41.6241 65.2159C41.6658 65.2702 41.6842 65.3389 41.6754 65.4069C41.6666 65.4749 41.6313 65.5366 41.5772 65.5784C41.5231 65.6202 41.4546 65.6387 41.3869 65.6299C40.298 65.4876 38.5466 65.9836 36.7033 66.9575C34.9914 67.8545 33.4826 69.0321 32.857 69.9394C32.6643 70.2188 32.4639 70.6083 32.6017 70.8672C32.6949 71.044 33.046 71.3388 34.3429 71.3183H34.431C35.8454 69.5806 38.9947 67.4034 41.5171 66.4641C42.5984 66.0617 43.3145 66.0323 43.6451 66.3783C43.7919 66.5307 43.9234 66.8178 43.7166 67.3137C43.0911 68.8181 39.467 71.6297 34.6876 71.8232C34.3047 72.3357 34.1502 72.7202 34.2038 72.9214C34.2715 73.1853 34.8293 73.261 35.6935 73.1251C40.7997 72.3178 43.312 69.6383 43.3375 69.6114C43.3839 69.5636 43.447 69.5358 43.5135 69.534C43.58 69.5322 43.6445 69.5565 43.6934 69.6018C43.7423 69.647 43.7716 69.7096 43.7753 69.7763C43.7789 69.8429 43.7565 69.9083 43.7128 69.9586C43.6068 70.074 41.0614 72.7945 35.7727 73.6313C35.5041 73.6741 35.2327 73.6964 34.9608 73.6979ZM42.9379 66.6499C42.703 66.6499 42.3188 66.7127 41.6958 66.9446C40.0108 67.5726 38.0449 68.7387 36.4365 70.0752C35.9796 70.4483 35.548 70.8516 35.1446 71.2824C39.4159 70.939 42.7005 68.4273 43.2456 67.1164C43.3209 66.9357 43.3324 66.7922 43.2762 66.7319C43.2622 66.714 43.183 66.6448 42.9379 66.6448V66.6499Z" fill="white"/>
            <path d="M100.74 229.263C100.674 229.263 100.611 229.238 100.563 229.192C100.516 229.147 100.488 229.084 100.485 229.018C100.473 228.762 100.214 222.611 102.379 220.513C102.627 220.261 102.929 220.067 103.261 219.946C103.593 219.825 103.948 219.781 104.3 219.815C105.598 219.92 105.994 220.69 106.098 221.318C106.278 222.409 105.699 224.069 104.553 225.762C103.43 227.419 102.042 228.72 100.841 229.243C100.809 229.256 100.775 229.263 100.74 229.263ZM104.077 220.317C103.827 220.316 103.579 220.365 103.348 220.462C103.118 220.559 102.909 220.701 102.734 220.881C101.031 222.533 100.965 227.21 100.985 228.601C103.325 227.319 105.915 223.347 105.595 221.401C105.485 220.741 105.048 220.389 104.259 220.325C104.198 220.326 104.138 220.317 104.078 220.317H104.077Z" fill="white"/>
            <path d="M106.294 230.486C104.37 230.486 101.925 229.769 100.644 229.244C100.601 229.226 100.563 229.196 100.535 229.157C100.507 229.119 100.491 229.074 100.487 229.026C100.484 228.979 100.493 228.932 100.515 228.89C100.536 228.847 100.569 228.812 100.61 228.788C100.819 228.659 105.762 225.7 107.987 226.722C108.556 226.985 108.907 227.471 109.027 228.17C109.155 228.939 108.974 229.526 108.476 229.927C107.978 230.328 107.192 230.486 106.294 230.486ZM101.336 228.962C103.379 229.704 106.953 230.5 108.157 229.528C108.503 229.248 108.623 228.832 108.524 228.257C108.432 227.728 108.187 227.378 107.775 227.188C106.971 226.819 105.531 227.049 103.611 227.852C102.833 228.181 102.074 228.551 101.336 228.962Z" fill="white"/>
            <path d="M63.9754 231.565C63.9302 231.564 63.8862 231.551 63.8478 231.527C63.8126 231.505 63.7833 231.474 63.7623 231.438C63.7414 231.402 63.7296 231.362 63.7278 231.32C63.715 231.064 63.4559 224.913 65.6209 222.814C65.869 222.563 66.1694 222.37 66.5004 222.25C66.8314 222.129 67.185 222.083 67.5357 222.116C68.8352 222.222 69.231 222.992 69.3344 223.62C69.5144 224.709 68.9361 226.371 67.7885 228.064C66.6651 229.721 65.2788 231.022 64.0775 231.543C64.0453 231.557 64.0106 231.565 63.9754 231.565ZM67.3136 222.619C67.0633 222.618 66.8153 222.667 66.5844 222.764C66.3535 222.861 66.1443 223.003 65.9694 223.183C64.2665 224.834 64.2014 229.512 64.2205 230.903C66.5604 229.621 69.1518 225.649 68.8301 223.703C68.7216 223.043 68.285 222.691 67.4949 222.627C67.4298 222.622 67.3685 222.619 67.3098 222.619H67.3136Z" fill="white"/>
            <path d="M69.5259 232.787C67.6008 232.787 65.1549 232.071 63.8758 231.546C63.8318 231.528 63.7936 231.498 63.7655 231.459C63.7374 231.421 63.7206 231.375 63.7169 231.328C63.7131 231.28 63.7227 231.233 63.7445 231.19C63.7662 231.148 63.7994 231.113 63.8401 231.088C64.0494 230.96 68.9923 228.002 71.2173 229.024C71.7879 229.28 72.1377 229.772 72.259 230.472C72.3866 231.241 72.2041 231.827 71.7075 232.229C71.2109 232.63 70.4233 232.787 69.5259 232.787ZM64.5665 231.262C66.6166 232.006 70.1833 232.8 71.3871 231.829C71.7343 231.549 71.8543 231.134 71.7547 230.559C71.6641 230.03 71.419 229.68 71.0054 229.49C70.2024 229.121 68.765 229.349 66.8477 230.15C66.0685 230.481 65.307 230.852 64.5665 231.262Z" fill="white"/>
            <path d="M95.7614 208.009H63.2988C63.2142 208.009 63.133 207.975 63.0732 207.915C63.0133 207.855 62.9797 207.774 62.9797 207.689C62.9797 207.604 63.0133 207.522 63.0732 207.462C63.133 207.402 63.2142 207.368 63.2988 207.368H95.7614C95.8461 207.368 95.9272 207.402 95.9871 207.462C96.0469 207.522 96.0806 207.604 96.0806 207.689C96.0806 207.774 96.0469 207.855 95.9871 207.915C95.9272 207.975 95.8461 208.009 95.7614 208.009Z" fill="white"/>
            <path d="M60.1585 208.009H27.6985C27.6139 208.009 27.5327 207.975 27.4729 207.915C27.413 207.855 27.3794 207.774 27.3794 207.689C27.3794 207.604 27.413 207.522 27.4729 207.462C27.5327 207.402 27.6139 207.368 27.6985 207.368H60.1585C60.2432 207.368 60.3243 207.402 60.3842 207.462C60.444 207.522 60.4777 207.604 60.4777 207.689C60.4777 207.774 60.444 207.855 60.3842 207.915C60.3243 207.975 60.2432 208.009 60.1585 208.009Z" fill="white"/>
            <path d="M22.6153 136.866C22.545 136.866 22.4766 136.843 22.4209 136.8C22.3651 136.757 22.3251 136.696 22.307 136.628C22.289 136.56 22.2939 136.487 22.321 136.422C22.3482 136.357 22.396 136.303 22.457 136.268L32.7779 130.338C32.8143 130.317 32.8544 130.304 32.896 130.299C32.9376 130.293 32.9798 130.296 33.0203 130.307C33.0608 130.318 33.0987 130.337 33.1319 130.363C33.1651 130.388 33.193 130.42 33.2138 130.457C33.2347 130.493 33.2482 130.534 33.2536 130.576C33.2589 130.617 33.256 130.66 33.2451 130.7C33.2341 130.741 33.2153 130.779 33.1897 130.812C33.164 130.846 33.1321 130.874 33.0957 130.895L22.7736 136.824C22.7253 136.851 22.6708 136.866 22.6153 136.866Z" fill="#101010"/>
            <path d="M29.8227 132.723C29.7807 132.723 29.7391 132.715 29.7003 132.699C29.6616 132.683 29.6263 132.659 29.5966 132.63C29.567 132.6 29.5435 132.564 29.5275 132.526C29.5115 132.487 29.5034 132.445 29.5035 132.403V127.816C29.5035 127.731 29.5372 127.65 29.597 127.59C29.6569 127.53 29.738 127.496 29.8227 127.496C29.9073 127.496 29.9885 127.53 30.0483 127.59C30.1082 127.65 30.1418 127.731 30.1418 127.816V132.403C30.1418 132.488 30.1082 132.569 30.0483 132.629C29.9885 132.689 29.9073 132.723 29.8227 132.723Z" fill="#101010"/>
            <path d="M44.863 133.64L28.4696 128.03V106.011L44.863 111.621V133.64Z" fill="#00923A"/>
            <path d="M44.863 133.64L61.2551 128.03V106.011L44.863 111.621V133.64Z" fill="#00923A"/>
            <path d="M43.8494 131.862C43.7817 131.862 43.7167 131.835 43.6688 131.787C43.621 131.739 43.5941 131.673 43.5941 131.605V111.615C43.5941 111.547 43.621 111.482 43.6688 111.433C43.7167 111.385 43.7817 111.358 43.8494 111.358C43.9171 111.358 43.982 111.385 44.0299 111.433C44.0778 111.482 44.1047 111.547 44.1047 111.615V131.605C44.1047 131.673 44.0778 131.739 44.0299 131.787C43.982 131.835 43.9171 131.862 43.8494 131.862Z" fill="#101010"/>
            <path d="M52.5133 136.947C52.5144 136.447 52.358 135.96 52.0666 135.555C51.7752 135.149 51.3637 134.847 50.8908 134.69C51.4255 134.516 51.8806 134.155 52.1745 133.674C52.4684 133.193 52.5817 132.622 52.4943 132.065C52.4068 131.507 52.1242 130.999 51.6972 130.632C51.2702 130.264 50.7268 130.062 50.1645 130.06L39.3138 130.036C39.0033 130.035 38.6958 130.096 38.4087 130.215C38.1216 130.333 37.8606 130.508 37.6406 130.728C37.4206 130.948 37.2459 131.209 37.1265 131.496C37.007 131.784 36.9452 132.093 36.9445 132.404C36.9445 133.456 36.9369 135.864 36.9343 136.912C36.9343 138.223 36.9267 140.348 36.9228 141.654C36.9222 141.966 36.9827 142.274 37.1008 142.563C37.219 142.851 37.3926 143.113 37.6117 143.334C37.8307 143.554 38.091 143.73 38.3776 143.85C38.6641 143.97 38.9714 144.032 39.2819 144.032L50.1325 144.058C50.4539 144.077 50.7758 144.03 51.0785 143.92C51.3812 143.81 51.6584 143.639 51.8929 143.418C52.1275 143.196 52.3146 142.929 52.4427 142.633C52.5709 142.336 52.6374 142.017 52.6382 141.693C52.6389 141.37 52.574 141.05 52.4473 140.753C52.3206 140.456 52.1348 140.188 51.9013 139.965C51.6678 139.743 51.3915 139.571 51.0893 139.459C50.7872 139.347 50.4655 139.299 50.144 139.316C50.4547 139.317 50.7624 139.257 51.0496 139.138C51.3369 139.019 51.598 138.845 51.8181 138.625C52.0381 138.405 52.2128 138.143 52.3321 137.855C52.4514 137.568 52.513 137.259 52.5133 136.947Z" fill="white"/>
            <path d="M50.8908 134.946L45.3634 134.933C45.2957 134.933 45.2307 134.906 45.1828 134.858C45.135 134.81 45.1081 134.745 45.1081 134.677C45.1081 134.609 45.135 134.544 45.1828 134.496C45.2307 134.448 45.2957 134.421 45.3634 134.421L50.8908 134.434C50.9585 134.434 51.0235 134.461 51.0713 134.509C51.1192 134.557 51.1461 134.622 51.1461 134.69C51.1461 134.758 51.1192 134.823 51.0713 134.871C51.0235 134.919 50.9585 134.946 50.8908 134.946Z" fill="#101010"/>
            <path d="M50.144 139.571L46.1318 139.562C46.0641 139.562 45.9992 139.535 45.9513 139.487C45.9034 139.439 45.8765 139.374 45.8765 139.306C45.8765 139.238 45.9034 139.173 45.9513 139.125C45.9992 139.077 46.0641 139.05 46.1318 139.05L50.144 139.059C50.2117 139.059 50.2767 139.086 50.3246 139.134C50.3724 139.182 50.3993 139.247 50.3993 139.315C50.3993 139.383 50.3724 139.448 50.3246 139.496C50.2767 139.544 50.2117 139.571 50.144 139.571Z" fill="#101010"/>
            <path d="M39.5653 130.194L43.4511 130.181C43.7286 130.18 44.0032 130.123 44.2584 130.014C44.5137 129.904 44.7444 129.745 44.937 129.544C45.1295 129.343 45.2799 129.106 45.3792 128.846C45.4786 128.585 45.5248 128.308 45.5153 128.029C45.4962 127.493 45.2716 126.986 44.8884 126.612C44.5052 126.238 43.993 126.028 43.4588 126.024L39.5883 125.997L39.5653 130.194Z" fill="white"/>
            <path d="M36.9445 132.404L37.0607 127.546C37.0707 127.128 37.2439 126.731 37.5428 126.44C37.8417 126.149 38.2426 125.987 38.659 125.99L41.2618 126.008L41.8401 131.379L36.9445 132.404Z" fill="white"/>
            <path d="M44.1775 130.303L39.3138 130.293C39.2461 130.293 39.1812 130.266 39.1333 130.218C39.0854 130.17 39.0585 130.105 39.0585 130.037C39.0585 129.969 39.0854 129.903 39.1333 129.855C39.1812 129.807 39.2461 129.78 39.3138 129.78L44.1787 129.791C44.2464 129.791 44.3114 129.818 44.3593 129.866C44.4071 129.914 44.434 129.979 44.434 130.047C44.434 130.115 44.4071 130.18 44.3593 130.228C44.3114 130.276 44.2464 130.303 44.1787 130.303H44.1775Z" fill="#101010"/>
            <path d="M69.1633 146.313C69.0968 146.313 69.032 146.292 68.978 146.253C68.9239 146.214 68.8834 146.159 68.862 146.096L66.577 139.409C66.5506 139.329 66.5568 139.242 66.5941 139.166C66.6315 139.091 66.697 139.033 66.7766 139.006C66.8561 138.978 66.9432 138.983 67.0189 139.02C67.0945 139.057 67.1527 139.122 67.1808 139.202L69.4658 145.888C69.4931 145.969 69.4874 146.057 69.4501 146.133C69.4128 146.21 69.3468 146.268 69.2667 146.296C69.2333 146.307 69.1984 146.313 69.1633 146.313Z" fill="#101010"/>
            <path d="M43.9336 236C43.8659 236 43.801 235.973 43.7531 235.925C43.7052 235.877 43.6783 235.812 43.6783 235.744C43.6413 233.876 43.0694 232.397 41.9754 231.347C40.1206 229.565 37.3581 229.612 37.3301 229.611C37.2626 229.613 37.1971 229.588 37.1481 229.541C37.099 229.494 37.0704 229.43 37.0684 229.362C37.0666 229.295 37.0917 229.229 37.1382 229.18C37.1846 229.131 37.2485 229.102 37.316 229.1C37.4437 229.1 40.3134 229.043 42.3252 230.973C43.5226 232.127 44.1494 233.723 44.1889 235.734C44.1889 235.802 44.162 235.867 44.1142 235.915C44.0663 235.963 44.0014 235.99 43.9336 235.99V236Z" fill="white"/>
            <path d="M80.3918 236C80.3241 236 80.2592 235.973 80.2113 235.925C80.1634 235.877 80.1365 235.811 80.1365 235.743C80.1008 233.875 79.5276 232.396 78.4349 231.347C76.5788 229.564 73.8163 229.612 73.7882 229.61C73.7216 229.61 73.6576 229.584 73.6099 229.538C73.5622 229.491 73.5346 229.427 73.5329 229.361C73.5321 229.326 73.538 229.292 73.5505 229.26C73.5629 229.229 73.5816 229.2 73.6054 229.175C73.6292 229.151 73.6576 229.131 73.689 229.118C73.7204 229.105 73.7542 229.098 73.7882 229.098C73.9159 229.098 76.7843 229.041 78.7961 230.971C79.9935 232.125 80.6203 233.721 80.6599 235.732C80.6599 235.8 80.633 235.865 80.5851 235.913C80.5372 235.961 80.4723 235.988 80.4046 235.988L80.3918 236Z" fill="white"/>
            <path d="M275.356 19.0563C274.646 18.9502 273.921 19.0703 273.282 19.3997C272.644 19.7292 272.125 20.2513 271.797 20.8926L272.052 19.1601C272.123 18.7103 272.104 18.251 271.997 17.8086C271.89 17.3662 271.696 16.9495 271.427 16.5827C271.159 16.2158 270.82 15.9061 270.432 15.6713C270.043 15.4365 269.612 15.2813 269.163 15.2148C268.715 15.1482 268.257 15.1716 267.818 15.2836C267.378 15.3955 266.965 15.5938 266.602 15.867C266.239 16.1402 265.934 16.4829 265.704 16.8752C265.474 17.2676 265.323 17.7018 265.261 18.1529L265.189 18.6526C265.26 17.7626 264.984 16.8797 264.419 16.1899C263.854 15.5001 263.045 15.0572 262.162 14.9545C261.278 14.8518 260.389 15.0974 259.683 15.6395C258.976 16.1817 258.506 16.978 258.373 17.8607L257.047 26.8155L256.08 24.637C255.726 23.8401 255.071 23.217 254.259 22.9046C253.448 22.5923 252.546 22.6162 251.752 22.9712C250.958 23.3268 250.337 23.9842 250.026 24.799C249.715 25.6139 249.738 26.5194 250.092 27.3166L252.336 32.386C252.336 32.386 273.399 34.7068 275.904 34.7285L278.253 22.9776C278.32 22.5291 278.298 22.0717 278.189 21.6316C278.079 21.1916 277.885 20.7775 277.615 20.4132C277.346 20.0489 277.008 19.7416 276.621 19.5087C276.233 19.2758 275.803 19.1221 275.356 19.0563Z" fill="white"/>
            <path d="M264.054 26.4492C264.035 26.4511 264.015 26.4511 263.996 26.4492C263.896 26.4337 263.806 26.3788 263.746 26.2966C263.685 26.2144 263.66 26.1117 263.676 26.0109L264.886 18.0992C264.903 17.9995 264.958 17.9105 265.039 17.8513C265.121 17.7921 265.222 17.7674 265.322 17.7828C265.421 17.7981 265.511 17.8521 265.571 17.9331C265.631 18.0142 265.657 18.1157 265.643 18.2158L264.433 26.1288C264.418 26.2186 264.371 26.3002 264.302 26.3588C264.232 26.4175 264.144 26.4495 264.054 26.4492Z" fill="#101010"/>
            <path d="M269.831 28.2111C269.791 28.211 269.75 28.2045 269.711 28.1919C269.663 28.1761 269.619 28.151 269.581 28.118C269.543 28.085 269.512 28.0449 269.489 27.9998C269.466 27.9546 269.453 27.9055 269.449 27.8551C269.445 27.8048 269.452 27.7542 269.467 27.7062L271.765 20.6441C271.781 20.596 271.806 20.5515 271.839 20.5132C271.872 20.4748 271.911 20.4433 271.956 20.4205C272.001 20.3976 272.05 20.3839 272.101 20.38C272.151 20.3762 272.201 20.3823 272.249 20.398C272.297 20.4138 272.341 20.4388 272.379 20.4717C272.418 20.5047 272.449 20.5448 272.472 20.5899C272.495 20.635 272.508 20.6842 272.512 20.7346C272.516 20.785 272.51 20.8357 272.494 20.8837L270.19 27.9458C270.165 28.0221 270.117 28.0887 270.053 28.1362C269.989 28.1838 269.911 28.21 269.831 28.2111Z" fill="#101010"/>
            <path d="M260.767 29.4887C260.696 29.4888 260.626 29.4693 260.566 29.4323L256.849 27.1423C256.804 27.1166 256.766 27.0823 256.735 27.0413C256.704 27.0004 256.681 26.9536 256.669 26.9038C256.656 26.854 256.654 26.8021 256.661 26.7513C256.669 26.7005 256.687 26.6518 256.714 26.608C256.741 26.5642 256.776 26.5262 256.818 26.4964C256.859 26.4665 256.906 26.4453 256.956 26.4341C257.006 26.4229 257.058 26.4218 257.108 26.431C257.159 26.4401 257.207 26.4593 257.25 26.4875L260.967 28.7762C261.038 28.8199 261.093 28.8858 261.124 28.9639C261.154 29.042 261.159 29.128 261.136 29.2087C261.113 29.2894 261.065 29.3605 260.998 29.411C260.931 29.4615 260.85 29.4888 260.767 29.4887Z" fill="#101010"/>
            <path d="M267.444 5.45885C267.364 5.45888 267.285 5.43338 267.22 5.38598C267.154 5.33858 267.106 5.27171 267.08 5.19487L265.528 0.504725C265.51 0.456303 265.502 0.404716 265.504 0.353052C265.506 0.301389 265.519 0.250715 265.541 0.204054C265.563 0.157394 265.595 0.115711 265.633 0.0815009C265.672 0.0472905 265.717 0.021254 265.766 0.00495273C265.815 -0.0113485 265.866 -0.0175822 265.918 -0.0133728C265.969 -0.00916348 266.019 0.0054012 266.065 0.0294494C266.11 0.0534976 266.151 0.0865341 266.183 0.126583C266.216 0.166633 266.24 0.212876 266.254 0.262535L267.808 4.95267C267.824 5.00063 267.83 5.05125 267.826 5.10162C267.823 5.15199 267.809 5.20112 267.786 5.24623C267.764 5.29133 267.732 5.33151 267.694 5.36448C267.656 5.39744 267.612 5.42254 267.564 5.43834C267.526 5.45199 267.485 5.45893 267.444 5.45885Z" fill="#101010"/>
            <path d="M259.865 9.78295C259.768 9.78294 259.675 9.74584 259.604 9.67915L256.006 6.30507C255.969 6.27076 255.939 6.22933 255.917 6.18318C255.896 6.13703 255.884 6.08709 255.882 6.03624C255.88 5.98539 255.888 5.93465 255.906 5.88696C255.923 5.83927 255.95 5.79559 255.985 5.75844C256.02 5.72129 256.061 5.69141 256.107 5.67054C256.154 5.64966 256.204 5.63822 256.254 5.63686C256.305 5.63549 256.355 5.64425 256.403 5.6626C256.45 5.68096 256.493 5.70855 256.53 5.74379L260.127 9.11788C260.183 9.1702 260.222 9.23803 260.239 9.31267C260.256 9.38731 260.25 9.46535 260.223 9.53681C260.195 9.60826 260.147 9.66986 260.085 9.71372C260.022 9.75757 259.948 9.78168 259.872 9.78295H259.865Z" fill="#101010"/>
            <path d="M274.943 6.3228C274.874 6.32288 274.808 6.30471 274.749 6.27016C274.69 6.23561 274.641 6.18593 274.608 6.12624C274.575 6.06656 274.558 5.99904 274.559 5.93065C274.561 5.86225 274.58 5.79546 274.616 5.73717L277.183 1.51989C277.236 1.43288 277.321 1.37057 277.42 1.34666C277.519 1.32275 277.623 1.33919 277.71 1.39238C277.796 1.44557 277.858 1.53114 277.882 1.63027C277.906 1.7294 277.89 1.83398 277.837 1.92098L275.269 6.13827C275.235 6.19457 275.187 6.24111 275.13 6.27343C275.073 6.30574 275.008 6.32274 274.943 6.3228Z" fill="#101010"/>
            <path d="M281.355 12.2469C281.271 12.2465 281.189 12.2184 281.123 12.167C281.056 12.1155 281.008 12.0435 280.987 11.9621C280.965 11.8807 280.97 11.7943 281.002 11.7164C281.034 11.6384 281.091 11.5731 281.163 11.5306L285.426 9.05734C285.514 9.00636 285.618 8.99256 285.716 9.019C285.814 9.04543 285.898 9.10993 285.949 9.1983C286 9.28666 286.013 9.39166 285.987 9.49019C285.961 9.58872 285.897 9.67271 285.809 9.72369L281.546 12.1956C281.488 12.2293 281.422 12.247 281.355 12.2469Z" fill="#101010"/>
            <path d="M157.859 222.079H185.598V91.0156H171.729L157.859 222.079Z" fill="#101010"/>
            <path d="M200.641 222.079H228.381L204.032 91.0156H190.162L200.641 222.079Z" fill="#101010"/>
            <path d="M202.533 60.6747H170.785C169.63 60.6752 168.49 60.9473 167.458 61.4691C166.426 61.9909 165.53 62.7479 164.842 63.6797L148.77 85.2005L144 73.0587L126.547 84.6123L135.627 110.731C137.323 115.216 144.87 120.214 151.982 114.368L164.237 99.2786L164.677 109.928L215.915 107.877L212.223 93.0315C241.973 112.888 278.664 100.254 277.37 31.4395L252.025 31.6804C252.964 55.4502 245.69 91.0158 202.533 60.6747Z" fill="#FF0000"/>
            <path d="M145.364 235.743H185.598V222.079H157.859L145.364 235.743Z" fill="#101010"/>
            <path d="M161.504 227.422C161.479 227.424 161.455 227.424 161.431 227.422L154.239 226.038C154.139 226.019 154.051 225.961 153.994 225.876C153.937 225.792 153.915 225.688 153.934 225.588C153.953 225.488 154.011 225.399 154.096 225.342C154.18 225.284 154.283 225.263 154.383 225.282L161.575 226.668C161.668 226.686 161.75 226.739 161.806 226.815C161.863 226.891 161.889 226.986 161.88 227.08C161.871 227.175 161.828 227.263 161.758 227.327C161.689 227.391 161.598 227.428 161.504 227.429V227.422Z" fill="white"/>
            <path d="M159.351 229.987C159.327 229.989 159.303 229.989 159.279 229.987L152.086 228.603C151.987 228.584 151.898 228.525 151.841 228.441C151.784 228.356 151.763 228.253 151.782 228.152C151.801 228.052 151.859 227.964 151.943 227.906C152.027 227.849 152.131 227.828 152.231 227.847L159.423 229.232C159.516 229.251 159.598 229.303 159.654 229.38C159.711 229.456 159.737 229.55 159.728 229.645C159.719 229.739 159.676 229.827 159.606 229.892C159.537 229.956 159.446 229.992 159.351 229.993V229.987Z" fill="white"/>
            <path d="M188.146 235.743H231.306L228.381 222.079H200.641L188.146 235.743Z" fill="#101010"/>
            <path d="M204.29 227.422C204.265 227.424 204.241 227.424 204.217 227.422L197.025 226.038C196.925 226.019 196.837 225.961 196.78 225.876C196.722 225.792 196.701 225.688 196.72 225.588C196.739 225.488 196.797 225.399 196.882 225.342C196.966 225.284 197.069 225.263 197.169 225.282L204.361 226.668C204.454 226.686 204.536 226.739 204.592 226.815C204.649 226.891 204.675 226.986 204.666 227.08C204.657 227.175 204.614 227.263 204.544 227.327C204.475 227.391 204.384 227.428 204.29 227.429V227.422Z" fill="white"/>
            <path d="M202.134 229.987C202.109 229.989 202.085 229.989 202.061 229.987L194.869 228.603C194.819 228.593 194.772 228.574 194.73 228.546C194.688 228.519 194.652 228.483 194.623 228.441C194.595 228.399 194.575 228.352 194.565 228.303C194.555 228.253 194.555 228.202 194.564 228.152C194.574 228.103 194.593 228.055 194.62 228.013C194.648 227.971 194.684 227.935 194.725 227.906C194.767 227.878 194.814 227.858 194.863 227.848C194.913 227.838 194.963 227.837 195.013 227.847L202.205 229.232C202.298 229.251 202.38 229.303 202.436 229.38C202.493 229.456 202.519 229.55 202.51 229.645C202.501 229.739 202.458 229.827 202.388 229.892C202.319 229.956 202.228 229.992 202.134 229.993V229.987Z" fill="#101010"/>
            <path d="M199.627 45.2733C199.959 42.7267 198.428 40.4256 196.207 40.1336C193.986 39.8417 191.917 41.6695 191.584 44.2161C191.252 46.7627 192.783 49.0638 195.004 49.3557C197.225 49.6477 199.295 47.8199 199.627 45.2733Z" fill="white"/>
            <path d="M174.563 54.2986C176.618 53.4052 177.46 50.7704 176.444 48.4134C175.427 46.0565 172.937 44.87 170.882 45.7633C168.826 46.6567 167.984 49.2915 169 51.6485C170.017 54.0054 172.507 55.1919 174.563 54.2986Z" fill="white"/>
            <path d="M192.251 55.8741C196.481 51.6284 196.481 44.7447 192.251 40.4989C188.022 36.2532 181.164 36.2532 176.935 40.4989C172.706 44.7447 172.706 51.6284 176.935 55.8741C181.164 60.1199 188.022 60.1199 192.251 55.8741Z" fill="white"/>
            <path d="M192.196 47.924C192.358 46.6819 191.486 45.5431 190.249 45.3805C189.012 45.2179 187.877 46.0929 187.715 47.335C187.553 48.5771 188.425 49.7158 189.662 49.8785C190.9 50.0411 192.034 49.166 192.196 47.924Z" fill="#00923A"/>
            <path d="M180.136 51.1466C180.298 49.9045 179.426 48.7658 178.189 48.6032C176.951 48.4405 175.817 49.3156 175.655 50.5576C175.493 51.7997 176.364 52.9385 177.602 53.1011C178.839 53.2638 179.973 52.3887 180.136 51.1466Z" fill="#00923A"/>
            <path d="M183.413 48.3111H183.38L181.25 48.0318C181.205 48.0259 181.163 48.0081 181.127 47.9802C181.091 47.9523 181.063 47.9153 181.046 47.873C181.029 47.8307 181.024 47.7846 181.031 47.7396C181.037 47.6945 181.056 47.652 181.085 47.6166C181.097 47.5999 182.398 45.9648 182.844 45.0062C182.857 44.9741 182.876 44.9449 182.901 44.9205C182.925 44.8961 182.955 44.877 182.987 44.8643C183.019 44.8517 183.054 44.8457 183.088 44.8468C183.123 44.8479 183.157 44.8561 183.188 44.8707C183.219 44.8854 183.247 44.9064 183.271 44.9323C183.294 44.9582 183.311 44.9885 183.322 45.0214C183.333 45.0544 183.337 45.0892 183.335 45.1238C183.332 45.1584 183.322 45.1921 183.306 45.2228C182.962 45.9622 182.18 47.0309 181.76 47.5807L183.446 47.8024C183.514 47.8068 183.577 47.838 183.622 47.8892C183.667 47.9404 183.689 48.0074 183.685 48.0753C183.681 48.1433 183.649 48.2067 183.598 48.2517C183.547 48.2966 183.481 48.3194 183.413 48.315V48.3111Z" fill="#101010"/>
            <path d="M185.856 48.4141C186.726 50.03 184.506 52.0829 182.374 50.399L185.856 48.4141Z" fill="#101010"/>
            <path d="M185.361 44.8268C185.441 44.931 185.549 45.0091 185.673 45.0513C185.797 45.0934 185.931 45.0977 186.057 45.0637C186.183 45.0296 186.297 44.9587 186.383 44.8599C186.469 44.7612 186.524 44.6389 186.541 44.5087C186.558 44.3785 186.536 44.2462 186.478 44.1285C186.42 44.0107 186.329 43.9129 186.215 43.8474C186.102 43.7818 185.972 43.7515 185.841 43.7603C185.711 43.769 185.586 43.8164 185.482 43.8965C185.344 44.004 185.253 44.1622 185.23 44.3365C185.207 44.5109 185.254 44.6872 185.361 44.8268Z" fill="#101010"/>
            <path d="M178.362 46.77C178.441 46.8746 178.549 46.9533 178.672 46.9962C178.796 47.0391 178.929 47.0443 179.056 47.011C179.182 46.9778 179.296 46.9077 179.383 46.8095C179.47 46.7113 179.526 46.5895 179.544 46.4595C179.561 46.3294 179.54 46.197 179.483 46.0789C179.426 45.9608 179.335 45.8624 179.223 45.7961C179.11 45.7297 178.98 45.6985 178.849 45.7062C178.719 45.714 178.594 45.7604 178.489 45.8397C178.35 45.9462 178.258 46.104 178.234 46.2784C178.21 46.4528 178.256 46.6296 178.362 46.77Z" fill="#101010"/>
            <path d="M174.499 51.3019C174.447 51.3018 174.396 51.2857 174.353 51.2558L170.55 48.6108C170.523 48.5916 170.499 48.5672 170.481 48.5389C170.463 48.5106 170.451 48.4789 170.445 48.4458C170.439 48.4126 170.439 48.3786 170.446 48.3457C170.453 48.3128 170.467 48.2816 170.486 48.2539C170.505 48.2263 170.529 48.2026 170.558 48.1844C170.586 48.1662 170.617 48.1537 170.65 48.1477C170.683 48.1417 170.717 48.1422 170.75 48.1494C170.783 48.1565 170.814 48.17 170.841 48.1892L174.644 50.838C174.689 50.869 174.723 50.9137 174.741 50.9655C174.759 51.0173 174.76 51.0735 174.744 51.1258C174.727 51.1782 174.695 51.2239 174.651 51.2563C174.607 51.2887 174.553 51.306 174.499 51.3057V51.3019Z" fill="#101010"/>
            <path d="M194.505 46.6011C194.468 46.6009 194.431 46.593 194.398 46.5781C194.367 46.5642 194.339 46.5444 194.316 46.5199C194.293 46.4953 194.275 46.4664 194.264 46.4349C194.252 46.4033 194.246 46.3697 194.247 46.336C194.248 46.3023 194.256 46.2692 194.27 46.2385L196.2 42.0097C196.213 41.9775 196.232 41.9484 196.257 41.924C196.281 41.8996 196.311 41.8804 196.343 41.8676C196.375 41.8549 196.409 41.8488 196.444 41.8497C196.478 41.8506 196.512 41.8586 196.543 41.8731C196.575 41.8876 196.603 41.9083 196.626 41.934C196.649 41.9597 196.667 41.9898 196.678 42.0226C196.689 42.0554 196.694 42.0901 196.691 42.1246C196.689 42.1592 196.679 42.1929 196.664 42.2237L194.735 46.4461C194.715 46.4914 194.683 46.5301 194.643 46.5576C194.602 46.5851 194.554 46.6002 194.505 46.6011Z" fill="#101010"/>
            <path d="M192.983 41.3062C192.983 41.3062 188.542 39.7544 184.628 36.2727C184.628 36.2727 187.896 31.8952 192.17 34.9989C194.592 36.7622 194.303 39.8364 192.983 41.3062Z" fill="#101010"/>
            <path d="M189.824 36.1935C189.824 36.1935 182.2 41.3103 174.043 45.9018C174.043 45.9018 169.282 38.7192 175.913 33.7869C184.075 27.7153 189.824 36.1935 189.824 36.1935Z" fill="#101010"/>
            <path d="M187.456 35.6394H187.44C187.373 35.6354 187.31 35.6047 187.265 35.554C187.22 35.5033 187.197 35.4368 187.2 35.369C187.209 35.2101 187.355 31.4708 182.283 30.1278C182.22 30.1088 182.166 30.0659 182.134 30.0082C182.102 29.9504 182.093 29.8823 182.109 29.8181C182.126 29.754 182.166 29.6987 182.223 29.664C182.279 29.6292 182.346 29.6177 182.411 29.6319C183.986 30.0496 186.085 30.98 187.134 33.047C187.517 33.7709 187.714 34.579 187.709 35.3984C187.705 35.4633 187.676 35.5242 187.629 35.5689C187.583 35.6137 187.52 35.6388 187.456 35.6394Z" fill="#101010"/>
            <path d="M185.598 212.236H158.928C158.86 212.236 158.795 212.209 158.747 212.161C158.699 212.113 158.672 212.048 158.672 211.98C158.672 211.912 158.699 211.847 158.747 211.799C158.795 211.751 158.86 211.724 158.928 211.724H185.598C185.666 211.724 185.731 211.751 185.779 211.799C185.827 211.847 185.854 211.912 185.854 211.98C185.854 212.048 185.827 212.113 185.779 212.161C185.731 212.209 185.666 212.236 185.598 212.236Z" fill="white"/>
            <path d="M226.504 212.236H199.833C199.765 212.236 199.7 212.209 199.653 212.161C199.605 212.113 199.578 212.048 199.578 211.98C199.578 211.912 199.605 211.847 199.653 211.799C199.7 211.751 199.765 211.724 199.833 211.724H226.504C226.572 211.724 226.637 211.751 226.685 211.799C226.732 211.847 226.759 211.912 226.759 211.98C226.759 212.048 226.732 212.113 226.685 212.161C226.637 212.209 226.572 212.236 226.504 212.236Z" fill="white"/>
            <path d="M210.785 82.6607C210.732 82.6605 210.68 82.6438 210.637 82.6131C210.595 82.5823 210.562 82.539 210.545 82.489L208.023 75.3282C208.011 75.2961 208.005 75.2619 208.006 75.2276C208.007 75.1932 208.015 75.1594 208.029 75.1281C208.043 75.0969 208.064 75.0688 208.089 75.0456C208.114 75.0224 208.144 75.0046 208.176 74.9931C208.209 74.9817 208.243 74.9769 208.277 74.979C208.311 74.9811 208.345 74.9901 208.375 75.0055C208.406 75.0209 208.433 75.0423 208.455 75.0684C208.478 75.0946 208.494 75.1249 208.505 75.1577L211.026 82.3185C211.048 82.3826 211.045 82.453 211.015 82.5143C210.986 82.5755 210.934 82.6227 210.87 82.6453C210.843 82.6553 210.814 82.6605 210.785 82.6607Z" fill="#101010"/>
            <path d="M142.269 79.9393L122.698 80.2341L114.348 56.8025L133.919 56.5078L142.269 79.9393Z" fill="#00923A"/>
            <path d="M142.269 79.9407L157.584 67.7078L149.235 44.2764L133.919 56.5092L142.269 79.9407Z" fill="#00923A"/>
            <path d="M140.419 78.4186C140.366 78.4185 140.315 78.402 140.272 78.3715C140.229 78.341 140.196 78.2979 140.179 78.2482L132.6 56.9759C132.587 56.9439 132.58 56.9094 132.58 56.8747C132.581 56.84 132.588 56.8057 132.602 56.7739C132.616 56.7421 132.636 56.7135 132.662 56.6898C132.687 56.6662 132.717 56.6479 132.749 56.6362C132.782 56.6244 132.817 56.6195 132.851 56.6216C132.886 56.6237 132.919 56.6328 132.95 56.6484C132.981 56.664 133.009 56.6857 133.031 56.7123C133.053 56.7389 133.07 56.7697 133.08 56.8029L140.659 78.0752C140.67 78.1069 140.675 78.1405 140.674 78.1742C140.672 78.2079 140.664 78.2409 140.649 78.2713C140.635 78.3018 140.615 78.3292 140.59 78.3518C140.565 78.3744 140.536 78.3919 140.504 78.4032C140.477 78.4132 140.448 78.4184 140.419 78.4186Z" fill="#101010"/>
            <path d="M140.116 77.6882C139.834 77.0905 139.352 76.6101 138.755 76.3296C138.159 76.049 137.483 75.9857 136.844 76.1505C137.373 75.7564 137.749 75.1901 137.908 74.5484C138.067 73.9067 137.999 73.2296 137.715 72.6329C137.39 71.9515 136.809 71.4275 136.099 71.1762C135.389 70.9248 134.609 70.9667 133.93 71.2925C134.61 70.9669 135.132 70.3836 135.384 69.6711C135.635 68.9586 135.594 68.1751 135.269 67.4929C135.109 67.155 134.883 66.8522 134.606 66.6018C134.329 66.3513 134.005 66.1582 133.654 66.0334C133.302 65.9085 132.929 65.8545 132.557 65.8742C132.184 65.894 131.82 65.9873 131.483 66.1487C131.82 65.9876 132.121 65.7615 132.371 65.4833C132.62 65.2052 132.813 64.8804 132.937 64.5275C133.061 64.1746 133.115 63.8006 133.095 63.4269C133.076 63.0531 132.983 62.6869 132.822 62.3492C132.662 62.0113 132.436 61.7085 132.159 61.4581C131.882 61.2077 131.559 61.0145 131.207 60.8897C130.856 60.7648 130.483 60.7108 130.111 60.7305C129.738 60.7503 129.374 60.8435 129.037 61.0049L120.349 65.1684C119.67 65.494 119.147 66.0772 118.896 66.7898C118.644 67.5023 118.686 68.2858 119.01 68.9679L126.303 84.3044C126.463 84.6423 126.689 84.9451 126.966 85.1956C127.243 85.446 127.567 85.6392 127.918 85.764C128.27 85.8888 128.643 85.9429 129.015 85.9231C129.388 85.9033 129.753 85.8101 130.089 85.6487L138.777 81.4852C139.456 81.1596 139.978 80.5768 140.229 79.8649C140.48 79.1529 140.44 78.3701 140.116 77.6882Z" fill="white"/>
            <path d="M127.175 81.1755C127.088 81.1756 127.003 81.146 126.935 81.0915C126.867 81.037 126.82 80.9609 126.801 80.8758C126.782 80.7906 126.792 80.7014 126.83 80.6229C126.868 80.5444 126.931 80.4812 127.01 80.4438L136.68 75.81C136.726 75.7855 136.776 75.7706 136.827 75.7661C136.879 75.7616 136.931 75.7676 136.98 75.7839C137.029 75.8001 137.074 75.8261 137.113 75.8605C137.152 75.8948 137.184 75.9367 137.206 75.9836C137.228 76.0305 137.241 76.0815 137.243 76.1334C137.245 76.1853 137.237 76.2371 137.218 76.2857C137.2 76.3343 137.172 76.3786 137.136 76.416C137.1 76.4534 137.057 76.4831 137.009 76.5033L127.339 81.1383C127.288 81.1623 127.232 81.175 127.175 81.1755Z" fill="#101010"/>
            <path d="M125.242 75.8427C125.155 75.8424 125.071 75.8127 125.004 75.7585C124.936 75.7042 124.889 75.6285 124.87 75.5437C124.85 75.459 124.86 75.3701 124.898 75.2918C124.935 75.2134 124.998 75.1501 125.076 75.1122L133.765 70.9475C133.857 70.9037 133.963 70.8982 134.058 70.9323C134.154 70.9664 134.232 71.0374 134.276 71.1295C134.32 71.2216 134.325 71.3273 134.291 71.4234C134.257 71.5196 134.187 71.5982 134.095 71.642L125.407 75.8055C125.355 75.83 125.299 75.8427 125.242 75.8427Z" fill="#101010"/>
            <path d="M122.796 70.6994C122.709 70.6998 122.624 70.6704 122.556 70.616C122.488 70.5616 122.44 70.4856 122.421 70.4003C122.402 70.3151 122.412 70.2258 122.45 70.1471C122.488 70.0685 122.552 70.0052 122.63 69.9677L131.318 65.8042C131.41 65.7602 131.516 65.7546 131.611 65.7886C131.707 65.8226 131.786 65.8934 131.83 65.9855C131.873 66.0776 131.879 66.1835 131.845 66.2797C131.811 66.376 131.741 66.4547 131.649 66.4988L122.961 70.6622C122.909 70.6868 122.853 70.6995 122.796 70.6994Z" fill="#101010"/>
            <path d="M80.1761 134.307C80.1297 134.307 80.0839 134.297 80.042 134.277C79.9654 134.241 79.906 134.176 79.8771 134.096C79.8481 134.016 79.8519 133.928 79.8876 133.851L81.3875 130.624C81.4043 130.585 81.4288 130.549 81.4596 130.519C81.4904 130.49 81.5269 130.466 81.5669 130.451C81.6068 130.436 81.6494 130.428 81.6922 130.43C81.735 130.431 81.777 130.442 81.8158 130.46C81.8546 130.478 81.8894 130.504 81.918 130.535C81.9467 130.567 81.9687 130.605 81.9826 130.645C81.9966 130.686 82.0024 130.729 81.9995 130.772C81.9966 130.815 81.9851 130.856 81.9658 130.895L80.4658 134.118C80.4409 134.174 80.4003 134.222 80.3489 134.255C80.2975 134.289 80.2374 134.307 80.1761 134.307Z" fill="#101010"/>
        </g>
        <defs>
            <clipPath id="clip4">
                <rect width="286" height="236" fill="white"/>
            </clipPath>
        </defs>
    </svg>

}